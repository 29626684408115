<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>
<input type="file" 
    class="media-capture"
    accept="image/*"
    (change)="bgCropper.imageChangedEvent = $event; bgCropper.open()"
    (click)="backgroundUpload.value = null"
    #backgroundUpload
>
<app-cropper-modal #bgCropper
    [headerText]="'Upload Background'"
    [confirmText]="'Done'"
    [maintainAspectRatio]="true"
    [aspectRatio]="16 / 9"
    [format]="'jpeg'"
    (croppedEvent)="onBackgroundCropped($event)"
>
</app-cropper-modal>
<div *ngIf="siteDetails">
    <sb-top-nav
        [faceShotImage]="blogSettings ? blogSettings.faceShotImage : null"
        [siteDetails]="siteDetails"
        [userBlogUrl]="blogSettings ? blogSettings.blogUrl : null"
    ></sb-top-nav>
    <sb-clean-blog-header
        imageContext="website"
        [backgroundImage]="getBackgroundImage()"
        [heading]="siteTitle"
        [subHeading]="siteSubtitle"
        [siteHeading]="true"
        
        [edit]="editable()"
        (editEvent)="backgroundUpload.click()"
        [save]="newBackgroundImage ? true : false"
        (saveEvent)="saveChanges()"
        [cancel]="newBackgroundImage ? true : false"
        (cancelEvent)="cancelChanges()">
    </sb-clean-blog-header>
    <ng-container *ngIf="blogs">
        <div class="container wr">
            <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                    <ng-container *ngIf="blogs.length; else noBlogsMessage">
                        <h1 i18n="blogs header|heading for highlighted blogs @@blogsHeading">Highlighted Blogs</h1>
                        <hr>
                        <div class="post-preview" *ngFor="let blog of blogs; let i = index">
                            <ng-container *ngIf="blog.blogUrl">
                                <a [routerLink]="['/', blog.blogUrl]">
                                    <h2 class="post-title">{{blog.blogTitle}}</h2>
                                    <h3 class="post-subtitle">{{blog.blogSubTitle}}</h3>
                                    <h3 class="post-subtitle name-field">{{blog.aboutMeName}}</h3>
                                </a>
                                <p class="post-meta serif"><span i18n="last post|last date @@lastPost">Last Post </span>: {{blog.dateCreated | date}}</p>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #noBlogsMessage>
                        <div class="empty-message serif">
                            <p i18n="placeholder blog list|empty list of blogs @@emptyBlogs">There are currently no blogs.</p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
    <sb-footer
        [facebook]="mrFacebookLink"
        [twitter]="mrTwitterLink"
    ></sb-footer>
</div>