import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, AuthGuardForSuperAdmin } from 'src/providers/auth-guard.service';
import { ContactComponent } from './components/contact/contact.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { CleanBlogHeaderModule } from './modules/clean-blog-header/clean-blog-header.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ShowAboutComponent } from './components/show-about/show-about.component';
import { MyHomeComponent } from './components/my-home/my-home.component';
import { PostComponent } from './components/post/post.component';
import { RouteTabComponent } from './components/route-tab/route-tab.component';
import { BlogSiteSettingsComponent } from './components/blog-site-settings/blog-site-settings.component';

const routes: Routes = [
    {
        path : '',
        component: HomeComponent
    },
    {
        path : 'login',
        component: LoginComponent
    },
    {
        path : 'site-settings',
        component: BlogSiteSettingsComponent,
        canActivate: [AuthGuardForSuperAdmin]
    },
    {
        path: ':blogUrl',
        component: MyHomeComponent
    },
    {
        path: ':blogUrl/contact',
        component: ContactComponent
    },
    {
        path : ':blogUrl/about',
        component: ShowAboutComponent
    },
    {
        path : ':blogUrl/routes',
        component: RouteTabComponent,
    },
    {
        path: ':blogUrl/post/:blogEntryId',
        component: PostComponent
    },
    {
        path: ':blogUrl',
        loadChildren: () => import('./modules/private/private.module').then(m => m.PrivateModule),
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
