import { Injectable } from "@angular/core";
import { Post } from "src/app/models/blog.model";
import { HttpHandlerService } from "./http-handler.service"
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from './login.service';
import { BlogSettings } from 'src/app/models/blog-settings.model';
import { HelperService } from './helper-service';
import { CacheService } from './cache.service';
import { environment, api_urls } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BlogService {

    selectedPostFromHome: Post;

    visitorBlogSettings: BlogSettings;

    constructor(
        private httpHandler: HttpHandlerService,
        private cookieService: CookieService,
        private loginService: LoginService,
        private helperService: HelperService,
        private cache: CacheService
    ){}
    getHighlightedBlogEntries(): Promise<any>{
        const url = api_urls.getHighlightedBlogEntries;
        return this.httpHandler.get(url)
        .then(res => {
            const payload = res['Payload'];
            const posts: Post[] = this.helperService.payloadToPosts(payload);
            return posts;
        });
    }
    getHighlightedBlogs(): Promise<any>{
        const url = api_urls.getHighlightedBlogs;
        return this.httpHandler.get(url)
        .then(res => {
            const payload = res.Payload;
            const blogSettingsArray = this.helperService.payloadToBlogSettingsArray(payload);
            this.cache.highlightedBlogs = blogSettingsArray;
            return blogSettingsArray;
        });
    }
    getCachedHighlightedBlogs(): Promise<any>{
        if(this.cache.highlightedBlogs){
            return Promise.resolve(this.cache.highlightedBlogs);
        }
        return this.getHighlightedBlogs();
    }
    savePost(keyValues, blogEntryId?: number){
        const url = api_urls.saveBlogEntry;
        const dateNow = new Date().toISOString();
        const payload = {
            "BlogEntryTitle": keyValues.heading,
            "BlogEntrySubTitle": keyValues.subHeading,
            "BlogEntryText": keyValues.body,
            "BlogEntryBackgroundUrl": keyValues.backgroundImage,
            "DateCreated": keyValues.dateCreated,
            "DateModified": dateNow
        }
        if(!! blogEntryId){
            payload['BlogEntryId'] = blogEntryId;
        }
        const token = this.cookieService.get('token');
        return this.httpHandler.post(url, payload, {token})
        .then(res => {
            return res.Payload[0];
        })
    }
    getBlogSettingsByToken(){
        const url = api_urls.getBlogSettings;
        const token = this.cookieService.get('token');
        return this.httpHandler.get(url, {token})
        .then(res => {
            const payload = res['Payload'];
            const bs: BlogSettings = this.helperService.payloadToBlogSettings(payload);
            this.visitorBlogSettings = bs;
            return bs;
        });
    }
    getUserBlogUrl(){
        const token = this.cookieService.get('token');
        if(!! token){
            return this.getVisitorBlogSettings()
            .then(bs => {
                return bs.blogUrl;
            })
        }
        else{
            return Promise.reject(new Error(environment.snackBarMessages.notLoggedInMessage));
        }
    }
    getBlogSettingsByUrl(blogUrl: string){
        const url = api_urls.getBlogSettingsByUrl(blogUrl);
        return this.httpHandler.get(url)
        .then(res => {
            const payload = res.Payload;
            const blogSettings = this.helperService.payloadToBlogSettings(payload);
            this.updateBloggerCache(blogSettings);
            return blogSettings;
        });
    }
    updateBloggerCache(bs: BlogSettings){
        this.cache.bloggerBS = null;
        this.cache.bloggerBS = {
            'blogUrl' : bs.blogUrl,
            'blogSettings' : bs
        }        
    }
    saveBlogSettings(blogSettings){
        const url = api_urls.saveBlogSettings;
        const token = this.cookieService.get('token');
        const payload = this.helperService.blogSettingsToPayload(blogSettings);
        return this.httpHandler.post(url, payload, {token});
    }
    saveBlogFaceShot(faceShotImage){
        const i = faceShotImage.indexOf(",");
        const faceshot = faceShotImage.slice(i+1);
        const url = api_urls.saveBlogFaceShot;
        const token = this.cookieService.get('token');
        const payload = {
            "BlogFaceShotImage" : faceshot
        }

        return this.httpHandler.put(url, payload, {token});
    }
    uploadImage(file){
        if(file.size > 5242880){
            return Promise.reject(new Error(environment.snackBarMessages.imageSizeLimitMessage));
        }
        const url = api_urls.uploadImage;
        const token = this.cookieService.get('token');
        const headers = {
            'token': token,
        };
        const formData = new FormData();
        formData.append('File', file);
        return this.httpHandler.postFormData(url, formData, headers)
        .then(res => {
            let payload = res.Payload;
            return payload.split(" ")[1];
        })
        .then(fileName => {
            let url = `https://mrblogimagestorage.blob.core.windows.net/blogimages/${fileName}`;
            return url;
        })
    }
    uploadBackgroundImage(file){
        const url = api_urls.uploadBackgroundImage;
        const token = this.cookieService.get('token');
        const headers = {
            'token': token,
        };
        const formData = new FormData();
        formData.append('File', file);
        return this.httpHandler.postFormData(url, formData, headers);
    }
    getBlogEntries(blogUrl: string){
        const url = api_urls.getBlogEntries(blogUrl);
        return this.httpHandler.get(url)
        .then(res => {
            const payload = res.Payload;
            const posts = this.helperService.payloadToPosts(payload)
            return posts;
        }) 
    }
    getBlogEntriesAndCache(blogUrl: string): Promise<any>{
        return this.getBlogEntries(blogUrl)
        .then(posts => {
            this.cache.bloggerP = null;
            this.cache.bloggerP = {
                'blogUrl' : blogUrl,
                'posts' : posts
            }
            return posts;
        })
    }
    getVisitorBlogEntries(blogUrl: string): Promise<any>{
        if(this.cache.visitorsBlogEntries){
            return Promise.resolve(this.cache.visitorsBlogEntries);
        }
        return this.getBlogEntries(blogUrl)
        .then(posts => {
            this.cache.visitorsBlogEntries = posts;
            return posts;
        });
    }
    getBlogEntry(blogUrl: string, blogEntryId: string){
        const url = api_urls.getBlogEntry(blogUrl, blogEntryId);
        return this.httpHandler.get(url)
        .then(res => {
            const payload = res.Payload;
            const posts = this.helperService.payloadToPosts(payload);
            return posts[0];
        })
    }
    sendBlogEmail(blogUrl, name, email, message){
        const url = api_urls.sendBlogEmail;
        const token = this.cookieService.get('token');
        const payload = {
            "BlogUrl": blogUrl,
            "SenderName": name,
            "SenderEmail": email,
            "Message": message
        }
        return this.httpHandler.post(url, payload, {token});
    }
    deleteBlog(){
        const url = api_urls.deleteBlog;
        const token = this.cookieService.get('token');

        return this.httpHandler.delete(url, {token});
    }
    deleteBlogEntry(blogEntryId: number){
        const url = api_urls.deleteBlogEntry(blogEntryId);
        const token = this.cookieService.get('token');

        return this.httpHandler.delete(url, {token});
    }

    // checkIfVisitorIsOwner(blogUrlOfContent: string){
    //     return this.getUserBlogUrl()
    //     .then(blogUrl => {
    //         if(blogUrl === blogUrlOfContent){
    //             return true;
    //         }
    //         else{
    //             return false;
    //         }
    //     })
    //     .catch(err => {
    //         return Promise.resolve(false);
    //     })
    // }
    getVisitorBlogSettings(){
        if(this.visitorBlogSettings){
            return Promise.resolve(this.visitorBlogSettings);
        }
        return this.getBlogSettingsByToken();
    }
    clearValues(){
        this.visitorBlogSettings = null;
    }

    getAvailableRoutesForMap(communityId: number) {
        const token = this.cookieService.get('token');
        const url = api_urls.getAvailableRoutesForMap(communityId);
        return this.httpHandler.get(url, {token})
        .then(res => {
            return res;
        });
    }

    getMatchPointsByCommunity(communityId) {
        const url = api_urls.getMatchPoints(communityId);
        const token = this.cookieService.get('token');
        return this.httpHandler.get(url, {token})
        .then(res => {
            return res;
        });
    }

    getCommunityById(communityId) {
        const url = api_urls.getCommunityById(communityId);
        const token = this.cookieService.get('token');
        return this.httpHandler.get(url, {token})
        .then(res => {
            return res;
        });
    }

    saveSiteSettings(payload){
        const url = api_urls.saveBlogSiteSettings;
        const token = this.cookieService.get('token');
        // const payload = this.helperService.blogSiteSettingsToPayload(blogSiteSettings);
        return this.httpHandler.post(url, payload, {token});
    }
}

