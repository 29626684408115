import { Component, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from 'src/providers/helper-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-cropper-modal',
    templateUrl: './cropper-modal.component.html',
    styleUrls: ['./cropper-modal.component.scss']
})
export class CropperModalComponent{


    @Input() headerText: string;
    @Input() confirmText: string;

    @Input() imageChangedEvent: any;
    @Input() maintainAspectRatio: boolean;
    @Input() aspectRatio: any;
    @Input() format: string;
    @Input() resizeToWidth;
    @Input() resizeToHeight;


    croppedImage: any;
    @ViewChild(TemplateRef, {static: false}) content;
    @Output() croppedEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        private helperService: HelperService,
        private modalService: NgbModal
    ){}
    imageCropped(event: ImageCroppedEvent){
        this.croppedImage = event.base64;
    }
    loadImageFailed(){
        this.helperService.showSnackBar(environment.snackBarMessages.imageLoadingFailed);
    }

    open(){
        this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
            () => {
                // On Save
                this.croppedEvent.emit(this.croppedImage);
                this.croppedImage = null;
                this.imageChangedEvent = null;
            },
            () => {
                // On Dismiss
                this.croppedImage = null;
                this.imageChangedEvent = null;
            });
    }
}