<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>
<input type="file"
    class="media-capture"
    accept="image/*"
    (change)="bgCropper.imageChangedEvent = $event; bgCropper.open()"
    (click)="backgroundUpload.value = null"
    #backgroundUpload
>
<app-cropper-modal #bgCropper
    [headerText]="'Upload Background'"
    [confirmText]="'Done'"
    [maintainAspectRatio]="true"
    [aspectRatio]="16 / 9"
    [format]="'jpeg'"
    (croppedEvent)="onBackgroundCropped($event)"
>
</app-cropper-modal>

<input type="file"
    class="media-capture"
    accept="image/*"
    (change)="fsCropper.imageChangedEvent = $event; fsCropper.open()"
    (click)="faceshotUpload.value = null"
    #faceshotUpload
>
<app-cropper-modal #fsCropper
    [headerText]="'Upload Photo'"
    [confirmText]="'Done'"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    [format]="'jpeg'"
    (croppedEvent)="onFaceshotCropped($event)"
>
</app-cropper-modal>
<sb-top-nav *ngIf="contentBlogSettings"
    [faceShotImage]="visitorBlogSettings ? visitorBlogSettings.faceShotImage : null"
    [siteDetails] = "siteDetails"
    [contentBlogUrl]="contentBlogSettings ? contentBlogSettings.blogUrl : null"
    [contentTitle]="contentBlogSettings.blogTitle"
    [userBlogUrl]="visitorBlogSettings ? visitorBlogSettings.blogUrl : null"
></sb-top-nav>
<sb-clean-blog-header *ngIf="contentBlogSettings"
    [edit]="edit"
    (editEvent)="backgroundUpload.click()"
    [save]="newBackgroundImage"
    (saveEvent)="saveBg()"
    [cancel]="newBackgroundImage"
    (cancelEvent)="cancelBg()"

    imageContext="aboutme"
    [backgroundImage]="backgroundImageUrl" 
    [siteHeading]="true" 
    [heading]="contentBlogSettings ? contentBlogSettings.aboutMeName : null"
>
</sb-clean-blog-header>
<div class="details" *ngIf="contentBlogSettings">
    <div class="profile">
        <div class="image-container">
            <img class="face-shot rounded-circle image_placeholder"
                alt="profile-photo"
                [src]="fsImageToShow ? fsImageToShow : getDefaultFaceShot()"
                i18n-alt="profile photo"
            >
            <div class="edit-icon" *ngIf="edit">
                <ul class="icons-list">
                    <li>
                        <fa-stack size="sm">
                            <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                            <fa-icon class="fa-stack-1x icon-element" (click)="faceshotUpload.click()" [icon]='["fas", "pencil-alt"]' stackItemSize="1x"></fa-icon>
                        </fa-stack>
                    </li>
                    <li *ngIf="newFaceshotImage">
                        <fa-stack size="sm">
                            <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                            <fa-icon class="fa-stack-1x icon-element" (click)="saveFs()" [icon]="faSave" stackItemSize="1x"></fa-icon>
                        </fa-stack>
                    </li>
                    <li *ngIf="newFaceshotImage">
                        <fa-stack size="sm">
                            <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                            <fa-icon class="fa-stack-1x icon-element" (click)="cancelFs()" [icon]="faWindowClose" stackItemSize="1x"></fa-icon>
                        </fa-stack>
                    </li>
                </ul>
            </div>
        </div>
        <div class="about-text">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-10 mx-auto">
                        <div class="subheading wr">{{contentBlogSettings.aboutMeText}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="toShowContactMe()" class="container text-center">
    <div class="row">
        <div class="col-12">
            <button class="btn btn-primary" [routerLink]="['/', contentBlogSettings.blogUrl, 'contact']"
            i18n="contact me"
            >Contact Me</button>
        </div>
    </div>
</div>
<sb-footer *ngIf="contentBlogSettings"
    [facebook]="contentBlogSettings.socialMediaLink1 ? contentBlogSettings.socialMediaLink1 : null"
    [twitter]="contentBlogSettings.socialMediaLink2 ? contentBlogSettings.socialMediaLink2 : null"
></sb-footer>