import { Component, OnInit } from '@angular/core';
import { BlogSettings } from 'src/app/models';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BlogService } from 'src/providers/blog.service';
import { HelperService } from 'src/providers/helper-service';
import { CacheService } from 'src/providers/cache.service';
import { LoginService } from 'src/providers/login.service';
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit{
    isLoading: boolean;
    isLoggedIn: boolean;
    siteDetails;
    contentBlogSettings: BlogSettings;
    visitorBlogSettings: BlogSettings;
    contactForm: FormGroup;
    recaptcha_site_key: string = environment.RECAPTCHA_SITE_KEY;
    constructor(
        private router: Router,
        private fb: FormBuilder,
        private blogService: BlogService,
        private helperService: HelperService,
        private activatedRoute: ActivatedRoute,
        private cache: CacheService,
        private loginService: LoginService
    ){}
    ngOnInit(){
        this.isLoading = true;
        this.isLoggedIn = this.loginService.checkLoggedIn();
        const contentBlogUrl = this.activatedRoute.snapshot.params['blogUrl'];
        let bloggerBlogSettingsPromise;
        if(this.cache.bloggerBS && this.cache.bloggerBS.blogUrl === contentBlogUrl){
            bloggerBlogSettingsPromise = this.cache.getBloggerBlogSettings();
        }
        if(!this.isLoggedIn){
            const siteDetailsPromise = this.helperService.getSiteDetails();
            const contentBsPromise = bloggerBlogSettingsPromise ? bloggerBlogSettingsPromise : this.blogService.getBlogSettingsByUrl(contentBlogUrl);
            Promise.all([siteDetailsPromise, contentBsPromise])
            .then(resolutions => {
                this.siteDetails = resolutions[0];
                this.contentBlogSettings = resolutions[1];

                if(this.contentBlogSettings.contactable){
                    this.contactForm = this.fb.group({
                        'name': ['', [Validators.required]],
                        'email': ['', [Validators.required, Validators.email]],
                        'message': ['', [Validators.required]],
                        'recaptcha': [null, [Validators.required]]
                    })
                }
                this.isLoading = false;

                if(bloggerBlogSettingsPromise){
                    this.blogService.getBlogSettingsByUrl(contentBlogUrl)
                    .then(bs => {
                        this.contentBlogSettings = bs;
                    })
                }
            })
            .catch(err => {
                this.isLoading = false;
                this.helperService.errorHandler(err);
            })
        }
        else{
            const siteDetailsPromise = this.helperService.getSiteDetails();
            const visitorBsPromise = this.blogService.getVisitorBlogSettings();

            Promise.all([siteDetailsPromise, visitorBsPromise])
            .then(resolutions => {
                this.siteDetails = resolutions[0];
                this.visitorBlogSettings = resolutions[1];
                if(this.visitorBlogSettings.blogUrl === contentBlogUrl){
                    return this.visitorBlogSettings;
                }
                else{
                    return bloggerBlogSettingsPromise ? bloggerBlogSettingsPromise : this.blogService.getBlogSettingsByUrl(contentBlogUrl);
                }
            })
            .then(contentBlogSettings => {
                this.contentBlogSettings = contentBlogSettings;
                if(this.contentBlogSettings.contactable){
                    this.contactForm = this.fb.group({
                        'name': ['', [Validators.required]],
                        'email': ['', [Validators.required, Validators.email]],
                        'message': ['', [Validators.required]],
                        'recaptcha': [null, [Validators.required]]
                    })
                }
                if(bloggerBlogSettingsPromise){
                    this.blogService.getBlogSettingsByUrl(contentBlogUrl)
                    .then(bs => {
                        this.contentBlogSettings = bs;
                    })
                }
                this.isLoading = false;
            })
            .catch(err => {
                this.isLoading = false;
                this.helperService.errorHandler(err);
            })
        }

    }
    getBackgroundImageUrl(){
        return this.contentBlogSettings.aboutMeBackgroundUrl ? `url(${this.contentBlogSettings.aboutMeBackgroundUrl})` : null;
    }
    getControlValue(fieldName: string){
        return (this.contactForm.get(fieldName) as FormControl).value;
    }
    onSubmit(){
        this.isLoading = true;
        this.blogService.sendBlogEmail(
            this.contentBlogSettings.blogUrl,
            this.getControlValue("name"),
            this.getControlValue("email"),
            this.getControlValue("message")
        )
        .then( _ => {
            this.helperService.showSnackBar(environment.snackBarMessages.messageSent);
            return this.router.navigate(['/', this.contentBlogSettings.blogUrl, 'about'])
        })
        .then( navigated => {
            if(navigated){
                this.isLoading = false;
            }
        })
        .catch(err => {
            this.contactForm.reset();
            this.isLoading = false;
            this.helperService.errorHandler(err);
        })
    }
    isMyContent(){
        if(this.visitorBlogSettings && (this.visitorBlogSettings.blogUrl === this.contentBlogSettings.blogUrl)){
            return true;
        }
        return false;
    }
}