import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from 'src/modules/icons/icons.module';
import { NavigationModule } from 'src/modules/navigation/navigation.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IconsModule,
        NavigationModule,
        MatProgressSpinnerModule
    ],
    declarations: [],
    providers: [],
    exports: [
        IconsModule,
        NavigationModule,
        MatProgressSpinnerModule
    ]
})
export class SharedModule{}