<footer class="route-footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 mx-auto">
                <ul class="list-inline">
                    <li class="list-inline-item">
                        <span>Bürgerbus &copy; 2022  Match Rider.</span>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-4 mx-auto">
                <ul class="list-inline text-center social-icons">
                    <li class="list-inline-item" *ngIf="facebook" style="font-size: 12px;">
                        <a [href]="facebook" target="_blank"
                            ><fa-stack size="lg"><fa-icon class="fa-stack-2x" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon><fa-icon class="fa-stack-1x fa-inverse" [icon]='["fab", "facebook-f"]' stackItemSize="1x"></fa-icon></fa-stack
                        ></a>
                    </li>
                    <li class="list-inline-item" *ngIf="twitter" style="font-size: 12px;">
                        <a [href]="twitter" target="_blank"
                            ><fa-stack size="lg"><fa-icon class="fa-stack-2x" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon><fa-icon class="fa-stack-1x fa-inverse" [icon]='["fab", "twitter"]' stackItemSize="1x"></fa-icon></fa-stack
                        ></a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-4 col-md-4 mx-auto">
                <ul class="list-inline text-end">
                    <li class="list-inline-item matchrider">
                        <span class="ml-auto">Powered by <a href="https://www.matchrider.de/">Match Rider</a></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
