import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from 'src/providers/blog.service';
import { HelperService } from 'src/providers/helper-service';
import { LoginService } from 'src/providers/login.service';
import { environment, MatchRiderSocialLinks } from 'src/environments/environment';
import { base64ToFile } from 'ngx-image-cropper';
import { BlogSettings } from 'src/app/models';
import { Subscription } from 'rxjs';
import { TopNavComponent } from 'src/modules/navigation/containers';
import { CleanBlogHeaderComponent } from 'src/app/modules/clean-blog-header/clean-blog-header.component';

@Component({
  selector: 'app-blog-site-settings',
  templateUrl: './blog-site-settings.component.html',
  styleUrls: ['./blog-site-settings.component.scss']
})
export class BlogSiteSettingsComponent implements OnInit {
  @ViewChild(TopNavComponent) topNav: TopNavComponent;
  @ViewChild(CleanBlogHeaderComponent) blogHead: CleanBlogHeaderComponent;
  isLoggedIn = false;
  isLoading: boolean = false;
  subscriptions: Subscription[] = [];

  siteDetails;

  blogSettings: BlogSettings;
  siteTitle: string = '';
  siteSubtitle: string = '';
  routeSiteUrl;
  newBackgroundImage;
  bgImageToShow;
  newBlogIconImage;
  blogIconImageToShow;
  blogIconUrl;
  backgroundImageUrl;
  blogHeading;
  blogSubHeading;
  blogTitle;
  routeManagementSiteUrl;
  mrFacebookLink = MatchRiderSocialLinks.facebook;
  mrTwitterLink = MatchRiderSocialLinks.twitter;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private helperService: HelperService,
    private blogService: BlogService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.isLoggedIn = this.loginService.checkLoggedIn();
    const promises = [];
    const siteDetailsPromise = this.helperService.getSiteDetails();
    promises.push(siteDetailsPromise);

    if (this.isLoggedIn) {
      const bsPromise = this.blogService.getVisitorBlogSettings();
      promises.push(bsPromise);
    }
    Promise.all(promises)
      .then(resolutions => {
        this.siteDetails = resolutions[0];
        this.bgImageToShow = this.siteDetails.BlogSiteBackgroundUrl;
        this.siteTitle = this.siteDetails.BlogSiteTitle;
        this.siteSubtitle = this.siteDetails.BlogSiteSubTitle;
        this.routeSiteUrl = this.siteDetails.RouteManagementSiteUrl;
        if (resolutions[1]) {
          this.blogSettings = resolutions[1];
        }
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        this.helperService.errorHandler(err);
      });

  }

  getBackgroundImage() {
    return this.bgImageToShow ? `url("${this.bgImageToShow}")` : null;
  }

  getBlogIconImage() {
    return this.blogIconImageToShow ? `url("${this.blogIconImageToShow}")` : '../../../assets/img/matchrider-logo.png';
  }

  onBackgroundCropped(croppedImage) {
    this.newBackgroundImage = croppedImage;
    this.bgImageToShow = croppedImage;
  }

  onBlogIconImageCropped(croppedImage) {
    this.newBlogIconImage = croppedImage;
    this.blogIconImageToShow = croppedImage;
  }

  cancelChanges() {
    this.newBackgroundImage = null;
    this.bgImageToShow = this.siteDetails.BlogSiteBackgroundUrl;
  }

  saveChanges() {
    this.isLoading = true;
    let file = base64ToFile(this.newBackgroundImage);
    this.blogService.uploadImage(file)
      .then(url => {
        this.backgroundImageUrl = url;
        this.newBackgroundImage = null;
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        this.helperService.errorHandler(err);
      })
  }

  saveBlogIcon() {
    this.isLoading = true;
    let file = base64ToFile(this.newBlogIconImage);

    this.blogService.uploadImage(file)
      .then(url => {
        this.blogIconUrl = url;
        this.newBlogIconImage = null;
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        this.helperService.errorHandler(err);
      })
  }

  cancelBlogIcon() {
    this.newBlogIconImage = null;
    this.blogIconImageToShow = null;
  }

  editable() {
    if (this.isLoggedIn && this.blogSettings && this.blogSettings.isSuperUser) {
      return true;
    }
    return false;
  }

  isBlogSiteSettingsValid() {
    if (!this.blogTitle) {
      this.helperService.showSnackBar('Title ' + environment.snackBarMessages.emptyField);
      return false;
    } else if (!this.blogHeading) {
      this.helperService.showSnackBar('Heading ' + environment.snackBarMessages.emptyField);
      return false;
    } else if (!this.blogSubHeading) {
      this.helperService.showSnackBar('Sub-Heading ' + environment.snackBarMessages.emptyField);
      return false;
    } else if (!this.routeManagementSiteUrl) {
      this.helperService.showSnackBar('Route Management URL ' + environment.snackBarMessages.emptyField);
      return false;
    }
    return true;
  }

  constructBlogSiteSettings() {
    let blogSiteSettings = this.siteDetails;
    for (let bs in blogSiteSettings) {
      if (!blogSiteSettings[bs] && bs != "BlogSiteConnectorId") {
        bs = "";
      }
    }
    blogSiteSettings.BlogSiteTitle = this.blogHeading;
    blogSiteSettings.BlogSiteSubTitle = this.blogSubHeading;
    blogSiteSettings.BlogSiteTitleShort = this.blogTitle;
    blogSiteSettings.RouteManagementSiteUrl = this.routeManagementSiteUrl;
    if (this.blogIconUrl) {
      blogSiteSettings.BlogSiteIconImage = this.blogIconUrl;
      blogSiteSettings.BlogSiteIconUrl = this.blogIconUrl;
    }
    if (this.backgroundImageUrl) {
      blogSiteSettings.BlogSiteBackgroundImage = this.backgroundImageUrl;
      blogSiteSettings.BlogSiteBackgroundUrl = this.backgroundImageUrl;
    }
    return blogSiteSettings;
  }

  updateSettings() {
    this.blogHeading = this.blogHead.heading;
    this.blogSubHeading = this.blogHead.subHeading;
    this.routeManagementSiteUrl = this.blogHead.routeManagementSiteUrl;
    this.blogTitle = this.topNav.siteDetails.BlogSiteTitleShort;
    if (this.isBlogSiteSettingsValid()) {
      const blogSiteSettings = this.constructBlogSiteSettings();
      const saveBsPromise = this.blogService.saveSiteSettings(blogSiteSettings);
      Promise.all([saveBsPromise])
        .then(_ => {
          this.helperService.showSnackBar(environment.snackBarMessages.settingsChanged);
          this.isLoading = false;
          this.router.navigate(["/"]);
        })
        .catch(err => {
          this.isLoading = false;
          this.helperService.errorHandler(err);
        })
    }
  }
}
