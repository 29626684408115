/* tslint:disable: ordered-imports*/
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* Modules */
// import { AppCommonModule } from '@common/app-common.module';

/* Components */
// import * as navigationComponents from './components';

/* Containers */
import * as navigationContainers from './containers';

/* Layouts */
import * as appCommonLayouts from './layouts'
import { IconsModule } from '../icons/icons.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

/* Guards */
// import * as navigationGuards from './guards';

/* Services */
// import * as navigationServices from './services';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, IconsModule, NgbModule, MatProgressSpinnerModule, InputTextModule],
    declarations: [
        ...navigationContainers.containers,
        // ...navigationComponents.components,
        ...appCommonLayouts.layouts,
    ],
    exports: [
        ...navigationContainers.containers,
        // ...navigationComponents.components,
        ...appCommonLayouts.layouts,
    ],
})
export class NavigationModule {
    // constructor(private navServices: navigationServices.NavigationService) {}

    static forRoot(): ModuleWithProviders<NavigationModule> {
        return {
            ngModule: NavigationModule,
            // providers: [...navigationServices.services, ...navigationGuards.guards],
            providers: []
        };
    }
}
