import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperModalComponent } from './components/cropper-modal/cropper-modal.component';

@NgModule({
    imports: [
        CommonModule,
        ImageCropperModule
    ],
    declarations: [
        CropperModalComponent
    ],
    providers: [],
    exports: [
        CropperModalComponent
    ]
})
export class ImageUploadHelperModule{

}