import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { HelperService } from 'src/providers/helper-service';

import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';


@Component({
    selector: 'sb-clean-blog-header',
    templateUrl: './clean-blog-header.component.html',
    styleUrls: ['clean-blog-header.component.scss'],
})
export class CleanBlogHeaderComponent {
    faSave = faSave;
    faWindowClose = faWindowClose;
    @Input() backgroundImage: string;
    @Input() heading!: string;
    @Input() subHeading!: string;
    @Input() meta!: string;
    @Input() siteHeading = false;
    @Input() edit = false;
    @Input() siteSettings = false;
    @Input() routeManagementSiteUrl: string;
    @Input() save: boolean;
    @Input() cancel: boolean;
    @Output() editEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveEvent: EventEmitter<any> = new EventEmitter();
    @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveSettingsEvent: EventEmitter<any> = new EventEmitter();

    @Input() imageContext: string ;

    constructor(
        private domSanitizer: DomSanitizer,
        private router: Router,
        private helperService: HelperService
        ) {}
    editClicked(){
        this.editEvent.emit(null);
    }

    saveClicked(){
        this.saveEvent.emit(null);
    }
    cancelClicked(){
        this.cancelEvent.emit(null);
    }
    getSafeBackgroundImage(){
        if(!this.backgroundImage){
            let bg = this.helperService.getImagesOnError(this.imageContext);
            bg = `url('${bg}')`;
            return this.domSanitizer.bypassSecurityTrustStyle(bg);
        }
        else{
            return this.domSanitizer.bypassSecurityTrustStyle(this.backgroundImage);
        }
    }

    updateSettings() {
        this.saveSettingsEvent.emit(null);
    }

    gotoHomePage() {
        this.router.navigate(["/"]);
    }
}
