import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
// import { AuthUtilsService } from '@modules/auth/services';
import { Post } from 'src/app/models/blog.model';
import { BlogService } from 'src/providers/blog.service';
import { LoginService } from 'src/providers/login.service';
import { HelperService } from 'src/providers/helper-service';
import { BlogSettings } from 'src/app/models';
import { base64ToFile } from 'ngx-image-cropper';
import { CacheService } from 'src/providers/cache.service';
import { environment } from 'src/environments/environment';
// import { Observable, Subscription } from 'rxjs';
// import { switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'sb-post',
    templateUrl: './post.component.html',
    styleUrls: ['post.component.scss'],
})
export class PostComponent implements OnInit{
    isLoggedIn: boolean = false;
    isLoading: boolean = true;
    post: Post;
    siteDetails;
    contentBlogUrl;
    contentBlogSettings: BlogSettings;
    visitorBlogSettings : BlogSettings;
    isVisitorOwner: boolean = false;

    newBackgroundImage;
    bgImageToShow;

    editButtonDetails: {
        toShow: boolean,
        blogEntryId: number
    } = {
        toShow: false,
        blogEntryId: 0
    }
    constructor(
        private blogService: BlogService,
        private loginService: LoginService,
        private router: Router,
        private helperService: HelperService,
        private activatedRoute: ActivatedRoute,
        private cache: CacheService
    ){
        const extras = this.router.getCurrentNavigation().extras;
        if(extras && extras.state && extras.state['post']){
            this.post = extras.state['post'];
        }
    }

    ngOnInit(){
        this.isLoggedIn = this.loginService.checkLoggedIn();
        
        this.isLoading = true;
        const blogUrl = this.activatedRoute.snapshot.params['blogUrl'];
        this.contentBlogUrl = blogUrl;
        const blogEntryId = this.activatedRoute.snapshot.params['blogEntryId'];

        let bloggerBlogSettingsPromise;
        if(this.cache.bloggerBS && this.cache.bloggerBS.blogUrl === this.contentBlogUrl){
            bloggerBlogSettingsPromise = this.cache.getBloggerBlogSettings();
        }

        const siteDetailsPromise = this.helperService.getSiteDetails();
        const postPromise = this.post ? Promise.resolve(this.post) : this.blogService.getBlogEntry(blogUrl, blogEntryId);
        if(!this.isLoggedIn){
            const contentBsPromise = bloggerBlogSettingsPromise ? bloggerBlogSettingsPromise : this.blogService.getBlogSettingsByUrl(this.contentBlogUrl);

            Promise.all([siteDetailsPromise, postPromise, contentBsPromise])
            .then(resolutions => {
                this.siteDetails = resolutions[0];
                this.post = resolutions[1];
                this.bgImageToShow = this.post.backgroundImage;
                this.contentBlogSettings = resolutions[2];

                if(bloggerBlogSettingsPromise){
                    this.blogService.getBlogSettingsByUrl(this.contentBlogUrl)
                    .then(bs => {
                        this.contentBlogSettings = bs;
                    })
                }
                this.isLoading = false;
            })
            .catch(err => {
                this.helperService.errorHandler(err);
                this.isLoading = false;
            })
        }
        else{
            const visitorBsPromise = this.blogService.getVisitorBlogSettings();

            Promise.all([siteDetailsPromise, postPromise, visitorBsPromise])
            .then(resolutions => {
                this.siteDetails = resolutions[0];
                this.post = resolutions[1];
                this.bgImageToShow = this.post.backgroundImage;
                this.visitorBlogSettings = resolutions[2];

                if(this.visitorBlogSettings.blogUrl === this.contentBlogUrl){
                    this.isVisitorOwner = true;
                    this.editButtonDetails = {
                        toShow : true,
                        blogEntryId: blogEntryId
                    }
                    return this.visitorBlogSettings;
                }
                else{
                    this.isVisitorOwner = false;
                    return bloggerBlogSettingsPromise ? bloggerBlogSettingsPromise : this.blogService.getBlogSettingsByUrl(this.contentBlogUrl);
                }
            })
            .then(bs => {
                this.contentBlogSettings = bs;
                if(bloggerBlogSettingsPromise){
                    this.blogService.getBlogSettingsByUrl(this.contentBlogUrl)
                    .then(bs => {
                        this.contentBlogSettings = bs;
                    })
                }
                this.isLoading = false;
            })
            .catch(err => {
                this.isLoading = false;
                this.helperService.errorHandler(err);
                
            })
        }
    }
    // ngOnInit(){
    //     this.isLoggedIn = this.loginService.checkLoggedIn();
        
    //     this.isLoading = true;
    //     const blogUrl = this.activatedRoute.snapshot.params['blogUrl'];
    //     this.contentBlogUrl = blogUrl;
    //     const blogEntryId = this.activatedRoute.snapshot.params['blogEntryId'];

    //     const siteDetails = this.helperService.getSiteDetails();
    //     const postPromise = this.post ? Promise.resolve(this.post) : this.blogService.getBlogEntry(blogUrl, blogEntryId);
    //     const promises = [siteDetails, postPromise];

    //     if(this.isLoggedIn){
    //         const visitorBlogSettingsPromise = this.blogService.getVisitorBlogSettings();
    //         const isVisitorOwnerPromise = this.blogService.checkIfVisitorIsOwner(blogUrl);
    //         promises.push(visitorBlogSettingsPromise, isVisitorOwnerPromise);
    //     }

    //     Promise.all(promises)
    //     .then(resolutions => {
    //         this.siteDetails = resolutions[0];
    //         this.post = resolutions[1];
    //         this.bgImageToShow = this.post.backgroundImage;
    //         if(resolutions[2]){
    //             this.visitorBlogSettings = resolutions[2];
    //         }
    //         if(resolutions[3]){
    //             this.isVisitorOwner = resolutions[3];
    //             if(this.isVisitorOwner){
    //                 this.editButtonDetails = {
    //                     toShow : true,
    //                     blogEntryId: blogEntryId
    //                 }
    //             }
    //         }
    //         this.isLoading = false;
    //     })
    //     .catch(err => {
    //         this.isLoading = false;
    //         this.helperService.errorHandler(err);
    //     })
    // }

    getBackgroundImageUrl(){
        if(this.bgImageToShow){
            return `url('${this.bgImageToShow}')`;
        }
        else if(this.contentBlogSettings.homePageBackgroundUrl){
            return `url('${this.contentBlogSettings.homePageBackgroundUrl}')`;
        }
        else{
            return null;
        }

    }

    onBackgroundCropped(croppedImage){
        this.newBackgroundImage = croppedImage;
        this.bgImageToShow = croppedImage;
    }
    cancelChanges(){
        this.newBackgroundImage = null;
        this.bgImageToShow = this.post.backgroundImage;
    }
    saveChanges(){
        this.isLoading = true;
        let file = base64ToFile(this.newBackgroundImage);

        let tempUrl;
        this.blogService.uploadImage(file)
        .then(url => {
            tempUrl = url;
            const keyValues = {
                heading: this.post.heading,
                subHeading: this.post.subHeading,
                body: this.post.body,
                backgroundImage: url,
                dateCreated: this.post.dateCreated
            }
            return this.blogService.savePost(keyValues, this.post.entryId);
        })
        .then( _ => {
            this.post.backgroundImage = tempUrl;
            this.bgImageToShow = tempUrl;
            this.newBackgroundImage = null;

            this.isLoading = false;
            this.helperService.showSnackBar(environment.snackBarMessages.postUpdated);
        })
        .catch(err => {
            this.isLoading = false;
            this.helperService.errorHandler(err);
        })
    }
}
