<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>
<sb-top-nav *ngIf="siteDetails"
    [siteDetails]="siteDetails"
    [disableFaceShot]="true"
></sb-top-nav>
<div class="full-background" *ngIf="siteDetails">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-8 mx-auto">
                <div>
                    <form class="mb-5 serif" id="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <h2 class="text-white mb-4" i18n="login">Login</h2>
                        <div class="form-group">
                            <input class="form-control py-4" type="text"
                                placeholder="Email" formControlName="email" [class.is-valid]="isEmailValid"
                                [class.is-invalid]="isEmailInvalid" i18n-placeholder="email"/>
                            <div class="invalid-feedback" *ngIf='emailControl.hasError("required")' i18n="email required|email is required">Email required</div>
                            <div class="invalid-feedback" *ngIf='emailControl.hasError("email")' i18n="email invalid|email is invalid">Email is not valid
                            </div>
                        </div>
                        <div class="form-group">
                            <input class="form-control py-4" type="password"
                                placeholder="Password" formControlName="password"
                                [class.is-valid]="isPasswordValid" [class.is-invalid]="isPasswordInvalid" i18n-placeholder="password"/>
                            <div class="invalid-feedback" *ngIf='passwordControl.hasError("required")' i18n="password required|password is required">Password required</div>
                        </div>
                        
                        
                        <div class="form-group d-flex align-items-center justify-content-between"><button
                                class="btn btn-primary" data-cy="createButton" type="submit"
                                [disabled]="loginForm.invalid" i18n="login">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
