<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>
<input type="file" 
    class="media-capture"
    accept="image/*"
    (change)="bgCropper.imageChangedEvent = $event; bgCropper.open()"
    (click)="backgroundUpload.value = null"
    #backgroundUpload
>
<app-cropper-modal #bgCropper
    [headerText]="'Upload Background'"
    [confirmText]="'Done'"
    [maintainAspectRatio]="true"
    [aspectRatio]="16 / 9"
    [format]="'jpeg'"
    (croppedEvent)="onBackgroundCropped($event)"
>
</app-cropper-modal>
<sb-top-nav *ngIf="siteDetails && post && contentBlogSettings"
    [faceShotImage]="visitorBlogSettings ? visitorBlogSettings.faceShotImage : null"
    [siteDetails]="siteDetails"
    [contentBlogUrl]="contentBlogUrl"
    [contentTitle]="contentBlogSettings.blogTitle"
    [userBlogUrl]="visitorBlogSettings ? visitorBlogSettings.blogUrl : null"
    [editButtonDetails]="editButtonDetails"
></sb-top-nav>
<sb-clean-blog-header *ngIf="post && contentBlogSettings"
    imageContext="post"
    [backgroundImage]="getBackgroundImageUrl()"
    [heading]="post.heading"
    [subHeading]="post.subHeading"
    [meta]="post.dateCreated | date"

    [edit]="isVisitorOwner"
    (editEvent)="backgroundUpload.click()"
    [save]="newBackgroundImage ? true : false"
    (saveEvent)="saveChanges()"
    [cancel]="newBackgroundImage ? true : false"
    (cancelEvent)="cancelChanges()"
>    
</sb-clean-blog-header>
<article *ngIf="post && contentBlogSettings">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-10 mx-auto serif">
                
                <markdown id="postPreview" ngPreserveWhitespaces *ngIf="post.body">{{post.body}}</markdown>
                <button class="btn btn-primary my-3"
                    *ngIf="isVisitorOwner" type="button" routerLink="edit" [state]="{'post': post}" i18n="edit post">Edit Post</button>
            </div>
        </div>
    </div>
</article>
<sb-footer *ngIf="contentBlogSettings"
    [facebook]="contentBlogSettings.socialMediaLink1 ? contentBlogSettings.socialMediaLink1 : null"
    [twitter]="contentBlogSettings.socialMediaLink2 ? contentBlogSettings.socialMediaLink2 : null"
></sb-footer>
