import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl, ControlContainer } from '@angular/forms';
import { LoginService } from 'src/providers/login.service';
import { Router } from '@angular/router';
import { BlogService } from 'src/providers/blog.service';
import { HelperService } from 'src/providers/helper-service';

import { environment } from "src/environments/environment";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{

    isLoading: boolean = false;
    loginForm : FormGroup = this.fb.group({
        'email': ['', [Validators.required, Validators.email]],
        'password': ['', [Validators.required]]
    });
    siteDetails;
    constructor(
        private fb: FormBuilder,
        private loginService: LoginService,
        private blogService: BlogService,
        private router: Router,
        private helperService: HelperService
    ){}

    ngOnInit(){
        this.isLoading = true;

        if(this.loginService.checkLoggedIn()){
            this.blogService.getVisitorBlogSettings()
            .then(bs => {
                this.router.navigate(["/", bs.blogUrl]);
                this.isLoading = false;
            })
            .catch(err => {
                this.isLoading = false;
                this.helperService.errorHandler(err);
            })
        }
        else{
            this.helperService.getSiteDetails()
            .then(siteDetails => {
                this.siteDetails = siteDetails;
                this.isLoading = false;
            })
            .catch(err => {
                this.helperService.errorHandler(err);
                this.isLoading = false;
            })
        }
    }

    onSubmit(){
        this.isLoading = true;
        this.loginService.login(this.emailControl.value, this.passwordControl.value)
        .then( _ => {
            return this.blogService.getUserBlogUrl();
        })
        .then( blogUrl => {
            this.isLoading = false;
            this.router.navigateByUrl(`/${blogUrl}`);
            //successful
            this.helperService.showSnackBar(environment.snackBarMessages.loginSuccess);
        })
        .catch(err => {
            this.isLoading = false;
            this.helperService.errorHandler(err);
        })
    }

    get emailControl(): FormControl{
        return this.loginForm.get('email') as FormControl;
    }
    get passwordControl(): FormControl{
        return this.loginForm.get('password') as FormControl;
    }

    get isEmailInvalid(): boolean{
        return this.emailControl.touched && !this.emailControl.valid; 
    }
    get isEmailValid(): boolean{
        return this.emailControl.touched && this.emailControl.valid;
    }

    get isPasswordInvalid(): boolean{
        return this.passwordControl.touched && !this.passwordControl.valid; 
    }
    get isPasswordValid(): boolean{
        return this.passwordControl.touched && this.passwordControl.valid;
    }
}

