<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>
<nav *ngIf="siteDetails" class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
    <div class="container">
        <div>
            <a *ngIf="!siteSettings" [href]="siteDetails['RouteManagementSiteUrl']" target="_blank">
                <div class="logo-container">
                <img class="logo-element rounded-circle" alt="Matchrider"
                    [src]="siteDetails.BlogSiteIconImage? siteDetails.BlogSiteIconImage: '../../../../assets/img/matchrider-logo.png'" />
                </div>
            </a>
            <div *ngIf="siteSettings && !save && !isSaveClicked" class="logo-container">
                <img class="logo-element rounded-circle" alt="Matchrider"
                    [src]="siteDetails.BlogSiteIconImage? siteDetails.BlogSiteIconImage: blogIconImage" />
            </div>
            <div *ngIf="siteSettings && (save || isSaveClicked)" class="logo-container">
                <div class="logo-element rounded-circle" [style.background-image]="getSafeIconImage()">
                </div>
            </div>
            <div *ngIf="siteSettings" class="edit-icon">
                <ul class="icons-list">
                    <li>
                        <fa-stack size="sm">
                            <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                            <fa-icon class="fa-stack-1x icon-element" (click)="editClicked()" [icon]='["fas", "pencil-alt"]' stackItemSize="1x"></fa-icon>
                        </fa-stack>
                    </li>
                    <li *ngIf="save">
                        <fa-stack size="sm">
                            <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                            <fa-icon class="fa-stack-1x icon-element" (click)="saveClicked()" [icon]="faSave" stackItemSize="1x"></fa-icon>
                        </fa-stack>
                    </li>
                    <li *ngIf="cancel">
                        <fa-stack size="sm">
                            <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                            <fa-icon class="fa-stack-1x icon-element" (click)="cancelClicked()" [icon]="faWindowClose" stackItemSize="1x"></fa-icon>
                        </fa-stack>
                    </li>
                </ul>
            </div>
        </div>
        <div class="navbar-brand">
            <a *ngIf="!siteSettings" class="navbar-brand m-0 p-0" routerLink="/">{{siteDetails.BlogSiteTitleShort}}</a>
            <div *ngIf="siteSettings" class="p-grid p-formgrid p-fluid site-settings">
                <div class="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                    <span *ngIf="siteSettings" class="p-float-label p-field p-mb-4">
                        <input id="blogTitle" type="text" name="title" autocomplete="off" pInputText [(ngModel)]="siteDetails.BlogSiteTitleShort">
                        <label for="blogTitle">Title</label>
                    </span>
                </div>
            </div>
            <span *ngIf="contentTitle && contentBlogUrl" class="blog-title">
                <span class="pipe-symbol">|</span>
                <a class="navbar-brand m-0 p-0" [routerLink]="['/', contentBlogUrl]">{{contentTitle}}</a>
            </span>
        </div>
        <button class="navbar-toggler navbar-toggler-right" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><fa-icon class="ml-1" [icon]='["fas", "bars"]'></fa-icon></button>
        <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
            <ul class="navbar-nav ml-auto navigation-list">
                <li class="nav-item" *ngIf="contentBlogUrl"><a class="nav-link" [routerLink]="['/', contentBlogUrl]" routerLinkActive="navigation-active" [routerLinkActiveOptions]="{ exact: true }" i18n="home">Home</a></li>
                <li class="nav-item" *ngIf="contentBlogUrl"><a class="nav-link" [routerLink]="['/', contentBlogUrl, 'about']" routerLinkActive="navigation-active" [routerLinkActiveOptions]="{ exact: true }" i18n="about">About</a></li>
                <li class="nav-item" *ngIf="contentBlogUrl && isShowRoute"><a class="nav-link" [routerLink]="['/', contentBlogUrl, 'routes']" routerLinkActive="navigation-active" [routerLinkActiveOptions]="{ exact: true }" i18n="routes">Routes</a></li>
                <li class="nav-item new-post" *ngIf="isLoggedIn && userBlogUrl && onMyBlog()"><a class="nav-link" [routerLink]="['/', userBlogUrl, 'create-new']" routerLinkActive="navigation-active" [routerLinkActiveOptions]="{ exact: true }" i18n="a new post">New Post</a></li>
            </ul>
            <div *ngIf="isLoggedIn && !disableFaceShot && userBlogUrl" ngbDropdown class="d-inline-block fs-dropdown">
                <div class="image-container" ngbDropdownToggle>
                    <img class="face-shot rounded-circle "
                        alt="profile-photo"
                        [src]="faceShotImage ? faceShotImage : getDefaultFaceShot()"
                    >
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdown">
                    <a ngbDropdownItem [ngClass]="{active: toHighlightMyHome()}" i18n="my home" (click)="routeToMyHome()">My Home</a>
                    <a ngbDropdownItem [routerLink]="['/', userBlogUrl, 'settings']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" i18n="settings">Settings</a>
                    <a *ngIf="editable()" ngbDropdownItem [routerLink]="['/', 'site-settings']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" i18n="blogSiteSettings">Site Settings</a>
                    <a ngbDropdownItem (click)="doLogout()" i18n="Logout">Logout</a>
                </div>
            </div>
        </div>
    </div>
</nav>