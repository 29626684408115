<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" i18n>{headerText, select, Upload Background {Upload Background} Upload Photo {Upload Photo} other {{{headerText}}}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="maintainAspectRatio"
        [aspectRatio]="aspectRatio"
        [resizeToWidth]="resizeToWidth"
        [resizeToHeight]="resizeToHeight"
        [format]="format"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close()" i18n>{confirmText, select, Done {Done} other {{{confirmText}}}}</button>
    </div>
</ng-template>