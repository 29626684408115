import { Component, OnInit } from "@angular/core"
import { Post, BlogSettings } from 'src/app/models';
import { BlogService } from 'src/providers/blog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/providers/helper-service';
import { base64ToFile } from 'ngx-image-cropper';
import * as _ from "lodash";
import { LoginService } from 'src/providers/login.service';
import { CacheService } from 'src/providers/cache.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-my-home',
    templateUrl: './my-home.component.html',
    styleUrls: ['./my-home.component.scss']
})
export class MyHomeComponent implements OnInit{

    isLoading: boolean = false;
    isLoggedIn: boolean;
    posts: Post[];
    visitorBlogSettings: BlogSettings;
    contentBlogSettings: BlogSettings;
    siteDetails;
    newBackgroundImage;
    bgImageToShow;
    constructor(
        private blogService: BlogService,
        private router : Router,
        private activatedRoute: ActivatedRoute,
        private helperService: HelperService,
        private loginService: LoginService,
        private cache: CacheService
    ){}

    ngOnInit(){
        this.isLoggedIn = this.loginService.checkLoggedIn();
        this.isLoading = true;
        
        const blogUrl = this.activatedRoute.snapshot.params['blogUrl'];

        let bloggerBlogSettingsPromise;
        let bloggerPostsPromise;
        if(this.cache.bloggerBS && this.cache.bloggerBS.blogUrl === blogUrl){
            bloggerBlogSettingsPromise = this.cache.getBloggerBlogSettings();
        }
        if(this.cache.bloggerP && this.cache.bloggerP.blogUrl === blogUrl){
            bloggerPostsPromise = this.cache.getBloggerPosts();
        }
        if(! this.isLoggedIn){
            const siteDetailsPromise = this.helperService.getSiteDetails();
            const postsPromise = bloggerPostsPromise ? bloggerPostsPromise : this.blogService.getBlogEntriesAndCache(blogUrl);
            const contentBsPromise = bloggerBlogSettingsPromise ? bloggerBlogSettingsPromise : this.blogService.getBlogSettingsByUrl(blogUrl);

            const promises = [siteDetailsPromise, postsPromise, contentBsPromise];
            Promise.all(promises)
            .then(resolutions => {
                this.siteDetails = resolutions[0];
                this.posts = resolutions[1];
                this.contentBlogSettings = resolutions[2];
                this.bgImageToShow = this.contentBlogSettings.homePageBackgroundUrl;

                this.isLoading = false;

                if(bloggerBlogSettingsPromise){
                    this.blogService.getBlogSettingsByUrl(blogUrl)
                    .then(bs => {
                        this.contentBlogSettings = bs;
                    })
                }
                if(bloggerPostsPromise){
                    this.blogService.getBlogEntriesAndCache(blogUrl)
                    .then(posts => {
                        this.posts = posts;
                    })
                }
            })
            .catch(err => {
                this.helperService.errorHandler(err);
                this.isLoading = false;
            })
        }
        else{
            const siteDetailsPromise = this.helperService.getSiteDetails();
            const visitorBlogSettings = this.blogService.getVisitorBlogSettings();
            const promises = [siteDetailsPromise, visitorBlogSettings];

            Promise.all(promises)
            .then(resolutions => {
                this.siteDetails = resolutions[0];
                this.visitorBlogSettings = resolutions[1];

                if(blogUrl === this.visitorBlogSettings.blogUrl){
                    const contentBsPromise = Promise.resolve(this.visitorBlogSettings);
                    const postsPromise = this.blogService.getVisitorBlogEntries(blogUrl);
                    return Promise.all([contentBsPromise, postsPromise]);
                }
                else{
                    const contentBsPromise = bloggerBlogSettingsPromise ? bloggerBlogSettingsPromise : this.blogService.getBlogSettingsByUrl(blogUrl);
                    const postsPromise = bloggerPostsPromise ? bloggerPostsPromise : this.blogService.getBlogEntriesAndCache(blogUrl);
                    return Promise.all([contentBsPromise, postsPromise]);
                }
            })
            .then(resolutions => {
                this.contentBlogSettings = resolutions[0];
                this.bgImageToShow = this.contentBlogSettings.homePageBackgroundUrl;
                this.posts = resolutions[1];

                this.isLoading = false;

                if(bloggerBlogSettingsPromise){
                    this.blogService.getBlogSettingsByUrl(blogUrl)
                    .then(bs => {
                        this.contentBlogSettings = bs;
                    })
                }
                if(bloggerPostsPromise){
                    this.blogService.getBlogEntriesAndCache(blogUrl)
                    .then(posts => {
                        this.posts = posts;
                    })
                }
            })
            .catch(err => {
                this.helperService.errorHandler(err);
                this.isLoading = false;
            })
            
        }

    }
    getBackgroundImage(){
        return this.bgImageToShow ? `url("${this.bgImageToShow}")` : null;
    }
    onBackgroundCropped(croppedImage){
        this.newBackgroundImage = croppedImage;
        this.bgImageToShow = croppedImage;
    }
    cancelChanges(){
        this.newBackgroundImage = null;
        this.bgImageToShow = this.contentBlogSettings.homePageBackgroundUrl;
    }
    saveChanges(){
        this.isLoading = true;
        let file = base64ToFile(this.newBackgroundImage);
        this.blogService.uploadImage(file)
        .then(url => {
            const bs = _.cloneDeep(this.visitorBlogSettings);
            bs.homePageBackgroundUrl = url;
            return this.blogService.saveBlogSettings(bs);
        })
        .then( _ => {
            return this.blogService.getBlogSettingsByToken();
        })
        .then(bs => {
            this.contentBlogSettings = bs;
            this.visitorBlogSettings = bs;
            this.bgImageToShow = this.contentBlogSettings.homePageBackgroundUrl;
            this.newBackgroundImage = null;

            this.isLoading = false;
            this.helperService.showSnackBar(environment.snackBarMessages.settingsChanged);
        })
        .catch(err => {
            this.isLoading = false;
            this.helperService.errorHandler(err);
        })
    }
    editable(){
        if(this.visitorBlogSettings && (this.visitorBlogSettings.blogUrl === this.contentBlogSettings.blogUrl)){
            return true;
        }
        return false;
    }
}