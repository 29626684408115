import { Injectable } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { BlogSettings, BlogSiteConnectorSettings, Post } from '../app/models';
import { HttpHandlerService } from './http-handler.service';
import { environment, api_urls, default_images } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: "root"
})
export class HelperService{
    
    siteDetails = null;

    constructor(
        private matSnackBar: MatSnackBar,
        private cookieService: CookieService,
        private router: Router,
        private httpHandler: HttpHandlerService,
        private titleService: Title
    ){}

    showSnackBar(message:string){
        this.matSnackBar.open(message, '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
        })
    }

    errorHandler(error){
        let errorMessage;
        if(error instanceof HttpErrorResponse){
            if(error.error instanceof ErrorEvent){
                errorMessage = error.message;
            }else{
                if(error.status === 401){
                    errorMessage = "Unauthorized Access";
                }else{
                    errorMessage = environment.snackBarMessages.basicErrorMessage;
                }
            }
        }else{
            errorMessage = error.message ? error.message : environment.snackBarMessages.basicErrorMessage;
        }
        this.showSnackBar(errorMessage);
    }

    blogSettingsToPayload(blogSettings: BlogSettings){
        const payload = {
            "BlogTitle": blogSettings.blogTitle,
            "BlogSubTitle": blogSettings.blogSubTitle,
            "FaceShotUrl" : blogSettings.faceShotUrl,
            "HomePageBackgroundUrl": blogSettings.homePageBackgroundUrl,
            "AboutMeBackgroundUrl": blogSettings.aboutMeBackgroundUrl,
            "AboutMeName": blogSettings.aboutMeName,
            "AboutMeText": blogSettings.aboutMeText,
            "SocialMediaType1": blogSettings.socialMediaType1,
            "SocialMediaLink1": blogSettings.socialMediaLink1,
            "SocialMediaType2": blogSettings.socialMediaType2,
            "SocialMediaLink2": blogSettings.socialMediaLink2,
            "SocialMediaType3": blogSettings.socialMediaType3,
            "SocialMediaLink3": blogSettings.socialMediaLink3,
            "HighlightedBlog": blogSettings.highlightedBlog,
            "ContactMe": blogSettings.contactable,
            "ShowRouteNetwork": blogSettings.routeTab,
            "DateCreated": blogSettings.dateCreated.toISOString(),
            "DateModified": new Date().toISOString()
        }
        return payload;
    }
    payloadToPosts(payload){
        let posts: Post[] = [];
        for(let item of payload){
            let post: Post = {
                blogId: item.BlogId,
                blogUrl: item.BlogUrl,
                entryId: item.BlogEntryId,
                heading: item.BlogEntryTitle,
                subHeading: item.BlogEntrySubTitle,
                body: item.BlogEntryText,
                isHiglighted: item.HighlightBlogEntry,
                dateCreated: new Date(item.DateCreated),
                dateModified: new Date(item.DateModified),
                backgroundImage: item.BlogEntryBackgroundUrl
            }
            posts.push(post);
        }
        return posts;
    }

    payloadToBlogSettingsArray(payload){
        const blogSettingsArray = [];
        for(let item of payload){
            const blogSetting = this.payloadToBlogSettings(item);
            blogSettingsArray.push(blogSetting);
        }
        return blogSettingsArray;
    }

    payloadToBlogSettings(payload){
        const bs: BlogSettings = {
            blogId : payload.BlogId,
            personId : payload.PersonId,
            blogTitle : payload.BlogTitle,
            blogSubTitle: payload.BlogSubTitle,
            blogUrl : payload.BlogUrl,
            faceShotUrl : payload.FaceShotUrl,
            homePageBackgroundUrl : payload.HomePageBackgroundUrl,
            aboutMeBackgroundUrl : payload.AboutMeBackgroundUrl,
            aboutMeName : payload.AboutMeName,
            aboutMeText : payload.AboutMeText,
            socialMediaType1 : payload.SocialMediaType1,
            socialMediaLink1 : payload.SocialMediaLink1,
            socialMediaType2 : payload.SocialMediaType2,
            socialMediaLink2 : payload.SocialMediaLink2,
            socialMediaType3 : payload.SocialMediaType3,
            socialMediaLink3 : payload.SocialMediaLink3,
            faceShotImage : payload.FaceShotUrl,
            highlightedBlog : payload.HighlightedBlog,
            isSuperUser: payload.IsSuperUser,
            contactable: payload.ContactMe,
            routeTab: payload.ShowRouteNetwork,
            communityId: payload.CommunityId,
            dateCreated : new Date(payload.DateCreated),
            dateModified : new Date(payload.DateModified)
        }
        return bs;
    }

    getSiteDetails(){
        if(this.siteDetails){
            return Promise.resolve(this.siteDetails)
        }
        else{
            const url = api_urls.getBlogSiteConnector;
            return this.httpHandler.get(url)
            .then(res => {
                const payload = res.Payload;
                this.siteDetails = payload;
                this.titleService.setTitle(this.siteDetails.BlogSiteTitleShort);
                return this.siteDetails;
            })
        }
    }
    updateCachedSiteDetails(): Promise<any>{
        const url = api_urls.getBlogSiteConnector;
        return this.httpHandler.get(url)
        .then(res => {
            const payload = res.Payload;
            this.siteDetails = payload;
            return this.siteDetails;
        })
    }
    hackyUpdateBgUrl(){
        this.siteDetails.BlogSiteBackgroundUrl = `${this.siteDetails.BlogSiteBackgroundUrl}?${new Date().getTime()}`;
        return this.siteDetails.BlogSiteBackgroundUrl;
    }
    getImagesOnError(type: string){
        switch(type){
            case 'faceshot': return `${default_images.faceshot}`;
            case 'website' : return `${default_images.website}`;
            case 'blogHome' : return `${default_images.blogHome}`;
            case 'aboutme' : return `${default_images.aboutme}`;
            case 'post' : return `${default_images.post}`;
            case 'contact' : return `${default_images.contact}`;
            case 'icon' : return `${default_images.icon}`;
        }
    }

    clearValues(){
        this.siteDetails = null;
    }

    blogSiteSettingsToPayload(blogSiteSettings: BlogSiteConnectorSettings){
        const payload = {
            "BlogSiteConnectorId": blogSiteSettings.blogSiteConnectorId,
            "BlogSiteUrl": blogSiteSettings.blogSiteUrl,
            "BlogSiteTitle": blogSiteSettings.blogSiteTitle,
            "BlogSiteTitleShort": blogSiteSettings.blogSiteTitleShort,
            "BlogSiteSubTitle": blogSiteSettings.blogSiteSubTitle,
            "RouteManagementSiteUrl": blogSiteSettings.routeManagementSiteUrl,
            "BlogSiteIconUrl": blogSiteSettings.blogSiteIconUrl,
            "RouteManagementSiteIconUrl": blogSiteSettings.routeManagementSiteIconUrl,
            "BlogSiteBackgroundUrl": blogSiteSettings.blogSiteBackgroundUrl,
            "BlogSiteIconImage": blogSiteSettings.blogSiteIconImage,
            "BlogSiteBackgroundImage": blogSiteSettings.blogSiteBackgroundImage,
            "BlogSiteFaviconImage": blogSiteSettings.blogSiteFaviconImage? blogSiteSettings.blogSiteFaviconImage: '',
            "BlogSiteFaviconText": blogSiteSettings.blogSiteFaviconText? blogSiteSettings.blogSiteFaviconText: ''
        }
        return payload;
    }
}