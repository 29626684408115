<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>

<sb-top-nav *ngIf="contentBlogSettings && contentBlogSettings.routeTab; else CannotShowRoutes"
    [faceShotImage]="visitorBlogSettings ? visitorBlogSettings.faceShotImage : null"
    [siteDetails]="siteDetails"
    [contentBlogUrl]="contentBlogSettings.blogUrl"
    [contentTitle]="contentBlogSettings.blogTitle"
    [userBlogUrl]="visitorBlogSettings ? visitorBlogSettings.blogUrl : null"
>
</sb-top-nav>

<sb-clean-blog-header *ngIf="contentBlogSettings && contentBlogSettings.routeTab"
    imageContext="contact"
    [backgroundImage]="getBackgroundImageUrl()"
    [heading]="'Routes'"
    [subHeading]="''"
    [siteHeading]="true"
>
</sb-clean-blog-header>

<div class="container">
    <div class="grid">
        <section class="col-9">
            <div #gmap style="height: calc(100vh - 230px); min-height: 300px; border:groove; width: 100%;"></div>    
        </section>

        <section *ngIf="isavailableRoute" class="col-3" style="height: calc(100vh - 230px); min-height: 300px; overflow-y: auto;">        
            <!-- <div class="p-col-12">
                <button pButton pRipple type="button" label="Reset" icon="pi pi-refresh" iconPos="right" class="p-button-outlined p-button-secondary" (click)="createRoute(availableRoutes)"></button>                
            </div>     -->

            <div class="col-12">
                <p-multiSelect [options]="groupedRoutes" [group]="true" [(ngModel)]="selectedLine" name="selectedLine" ngDefaultControl defaultLabel="Select Line"
                    optionLabel="highwayName" scrollHeight="250px" display="chip" (onChange)="selectedLinesOnMap()">
                    <ng-template let-group pTemplate="group">
                        <div class="p-d-flex p-ai-center">
                            <span>{{group.label}}</span>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </div>

            <div class="col-12 separator-line p-pt-2" (click)="createRoute(inComingRoute)" style="cursor: pointer;">
                <button pButton pRipple type="button" label="InComing" class="p-button-outlined p-button-danger col-12"></button>                
            </div>     
                
            <div class="col-12 p-pt-2" (click)="createRoute(outGoingRoute)" style="cursor: pointer;">
                <button pButton pRipple type="button" label="OutGoing" class="p-button-outlined col-12"></button>                                    
            </div>   
            
            <div class="col-12 p-pt-2" (click)="createRoute(circularRoute)" style="cursor: pointer;">
                <button pButton pRipple type="button" label="Circular" class="p-button-outlined p-button-success col-12"></button>                
            </div>
            
            <div class="col-12 separator-line p-pt-2">
                <button *ngIf="!isMPsChecked" pButton pRipple type="button" label="Show Matchpoints" class="p-button-outlined p-button-info col-12 p-pl-0 p-pr-0" style="font-size: 0.9rem;" [(ngModel)]="isMPsChecked" name="matchpoint" ngDefaultControl (click)="toggleMPsStop()"></button>
                <button *ngIf="isMPsChecked" pButton pRipple type="button" label="Hide Matchpoints" class="p-button-outlined p-button-info col-12 p-pl-0 p-pr-0" style="font-size: 0.9rem;" [(ngModel)]="isMPsChecked" name="matchpoint" ngDefaultControl (click)="toggleMPsStop()"></button>                
            </div>            
        </section>
    </div>
</div>

<!-- <div class="container">
    <div class="row">
        <section class="col-lg-10">
            <div #gmap style="height: 400px; min-height: 300px; border:groove; width: 100%;"></div>    
        </section>  
        
        <section *ngIf="isavailableRoute" class="col-lg-2 pl-0 available-route-section" style="height: 400px; min-height: 300px; overflow-y: auto;">        
            <div class="mb-2">
                <button type="button" (click)="createRoute(availableRoutes)" class="btn btn-outline-secondary col-lg-12">Reset <i class="pi pi-refresh" style="font-size: 0.8rem;"></i></button>
            </div>  
            
            <div class="col-lg-12 mb-2 px-0"> 
                <p-multiSelect [options]="routes" [(ngModel)]="selectedLine" name="selectedLine" ngDefaultControl defaultLabel="Select Line"
                optionLabel="id" display="chip" (onChange)="selectedLinesOnMap()"></p-multiSelect>
            </div>
            <div class="mb-2 separator-line p-pt-2" style="cursor: pointer;">
                <button type="button" (click)="createRoute(inComingRoute)" class="btn btn-outline-danger col-lg-12">InComing</button>
            </div>     
                
            <div class="mb-2" style="cursor: pointer;">
                <button type="button" (click)="createRoute(outGoingRoute)" class="btn btn-outline-primary col-lg-12">OutGoing</button>
            </div>   
            
            <div class="mb-2" style="cursor: pointer;">
                <button type="button" (click)="createRoute(circularRoute)" class="btn btn-outline-success col-lg-12">Circular</button>
            </div>
            
            <div class="p-field mb-2 separator-line p-pt-2">
                <button *ngIf="!isMPsChecked"  type="button" class="btn btn-outline-info col-lg-12" style="font-size: 0.8rem;" [(ngModel)]="isMPsChecked" name="matchpoint" ngDefaultControl (click)="toggleMPsStop()">Show Matchpoints</button>
                <button *ngIf="isMPsChecked" type="button" class="btn btn-outline-info col-lg-12" style="font-size: 0.8rem;" [(ngModel)]="isMPsChecked" name="matchpoint" ngDefaultControl (click)="toggleMPsStop()">Hide Matchpoints</button>
            </div>
        </section>
    </div>
</div> -->

<sb-footer *ngIf="contentBlogSettings && contentBlogSettings.routeTab"
    [facebook]="mrFacebookLink"
    [twitter]="mrTwitterLink"
></sb-footer>

<ng-template #CannotShowRoutes>
    <div class="row error-message">
        {{ routeToBlogUrl() }}
    </div>
</ng-template>