import { Injectable } from '@angular/core';
import { BlogSettings, Post } from 'src/app/models';

@Injectable({
    providedIn: "root"
})
export class CacheService{
    highlightedBlogs: BlogSettings[];
    visitorsBlogEntries: Post[];

    clearCache(){
        this.highlightedBlogs = null;
        this.visitorsBlogEntries = null;
    }

    //Here follows the General Purpose Caching
    bloggerBS: {
        blogUrl: string;
        blogSettings: BlogSettings;
    }
    bloggerP: {
        blogUrl: string;
        posts: Post[];
    }
    getBloggerBlogSettings(): Promise<any>{
        return Promise.resolve(this.bloggerBS.blogSettings);
    }
    getBloggerPosts(): Promise<any>{
        return Promise.resolve(this.bloggerP.posts);
    }
}