<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>
<input type="file" 
    class="media-capture"
    accept="image/*"
    (change)="bgCropper.imageChangedEvent = $event; bgCropper.open()"
    (click)="backgroundUpload.value = null"
    #backgroundUpload
>
<app-cropper-modal #bgCropper
    [headerText]="'Upload Background'"
    [confirmText]="'Done'"
    [maintainAspectRatio]="true"
    [aspectRatio]="16 / 9"
    [format]="'jpeg'"
    (croppedEvent)="onBackgroundCropped($event)"
>
</app-cropper-modal>

<input type="file"
    class="media-capture"
    accept="image/*"
    (change)="blogIconCropper.imageChangedEvent = $event; blogIconCropper.open()"
    (click)="faceshotUpload.value = null"
    #faceshotUpload
>
<app-cropper-modal #blogIconCropper
    [headerText]="'Upload Blog Logo'"
    [confirmText]="'Done'"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    [resizeToWidth]="44"
    [resizeToHeight]="44"
    [format]="'jpeg'"
    (croppedEvent)="onBlogIconImageCropped($event)"
>
</app-cropper-modal>
<div *ngIf="siteDetails">
    <sb-top-nav
        imageContext="icon"
        [faceShotImage]="blogSettings ? blogSettings.faceShotImage : null"
        [blogIconImage]="getBlogIconImage()"
        [siteDetails]="siteDetails"
        [userBlogUrl]="blogSettings ? blogSettings.blogUrl : null"
        [siteSettings]="editable()"
        [edit]="editable()"
        (editEvent)="faceshotUpload.click()"
        [save]="newBlogIconImage ? true : false"
        (saveEvent)="saveBlogIcon()"
        [cancel]="newBlogIconImage ? true : false"
        (cancelEvent)="cancelBlogIcon()"
        
    ></sb-top-nav>
    <sb-clean-blog-header
        imageContext="website"
        [backgroundImage]="getBackgroundImage()"
        [heading]="siteTitle"
        [subHeading]="siteSubtitle"
        [siteHeading]="true"
        [routeManagementSiteUrl]="routeSiteUrl"
        [siteSettings]="editable()"
        [edit]="editable()"
        (editEvent)="backgroundUpload.click()"
        [save]="newBackgroundImage ? true : false"
        (saveEvent)="saveChanges()"
        [cancel]="newBackgroundImage ? true : false"
        (cancelEvent)="cancelChanges()"
        (saveSettingsEvent)="updateSettings()">
    </sb-clean-blog-header>
    <sb-footer
        [facebook]="mrFacebookLink"
        [twitter]="mrTwitterLink"
    ></sb-footer>
</div>
