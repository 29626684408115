<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>
<sb-top-nav *ngIf="siteDetails && contentBlogSettings"
    [faceShotImage]="visitorBlogSettings ? visitorBlogSettings.faceShotImage : null"
    [siteDetails]="siteDetails"
    [contentBlogUrl]="contentBlogSettings.blogUrl"
    [contentTitle]="contentBlogSettings.blogTitle"
    [userBlogUrl]="visitorBlogSettings ? visitorBlogSettings.blogUrl : null"
>
</sb-top-nav>

<sb-clean-blog-header *ngIf="contentBlogSettings"
    imageContext="contact"
    [backgroundImage]="getBackgroundImageUrl()"
    [heading]="'Contact Me'"
    [subHeading]=""
    [siteHeading]="true"
>
</sb-clean-blog-header>

<div *ngIf="contentBlogSettings" class="container">
    <div *ngIf="contactForm && !isMyContent(); else CannotSendMessage" class="row">
        <div class="col-lg-8 col-md-10 mx-auto text-center serif" i18n="message|text to send the message">
            Want to get in touch? You can send me a message here.
        </div>
        <div class="col-lg-8 col-md-10 mx-auto form-fields">
            <form [formGroup]="contactForm" (submit)="onSubmit()">
                <mat-form-field class="serif" appearance="standard">
                    <mat-label i18n="name">Name</mat-label>
                    <input matInput formControlName="name">
                </mat-form-field>
                <br>
                <mat-form-field class="serif" appearance="standard">
                    <mat-label i18n="email">Email Address</mat-label>
                    <input matInput formControlName="email">
                </mat-form-field>
                <br>
                <mat-form-field class="serif" appearance="standard">
                <mat-label i18n="message">Message</mat-label>
                <textarea matInput formControlName="message"></textarea>
                </mat-form-field>
                <re-captcha [siteKey]="recaptcha_site_key" formControlName="recaptcha" #recaptchaRef></re-captcha>
                <div class="mx-auto mt-3 mb-5">
                    <button class="btn btn-primary" type="submit" [disabled]="!contactForm.valid" i18n="send">
                    Send
                    </button>
                </div>
            </form>
        </div>

    </div>
    <ng-template #CannotSendMessage>
        <div class="row error-message">
            <div *ngIf="isMyContent(); else contactDisabledMessage" class="col-lg-8 col-md-10 mx-auto text-center serif" i18n>
                Cannot send mail to self.
            </div>
            <ng-template #contactDisabledMessage>
                <div class="col-lg-8 col-md-10 mx-auto text-center serif" i18n>
                    User has disabled contact.
                </div>
            </ng-template>
        </div>
    </ng-template>
</div>

