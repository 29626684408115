import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root"
})
export class HttpHandlerService{
    constructor(
        private http: HttpClient
    ){}

    handleResponse = (res) => {
        if(!!res['Status']){
            if(res['Status'] === 'Success'){
                return Promise.resolve(res);
            }
            else if(res['Status'] === 'Failure' && res['Payload'][0] && res['Payload'][0].error){
                return Promise.reject(new Error(res['Payload'][0].error));
            }
            else{
                return Promise.reject(new Error(environment.snackBarMessages.basicErrorMessage));
            }
        }
        else{
            return Promise.reject(new Error(environment.snackBarMessages.basicErrorMessage));
        }
    }
    get(url: string, moreHeaders?, moreOptions?): Promise<any>{
        let headers = {
            'Content-Type' : 'application/json',
        }
        if(moreHeaders){
            for(let key in moreHeaders){
                headers[key] = moreHeaders[key];
            }
        }
        let options = {
            'headers' : new HttpHeaders(headers),
        }
        if(moreOptions){
            for(let key in moreOptions){
                options[key] = moreOptions[key];
            }
        }
        
        return this.http.get(url, options)
        .toPromise()
        .then(res => {
            return this.handleResponse(res);
        });
    }
    post(url, data, moreHeaders?, moreOptions?): Promise<any> {
        let headers = {
            'Content-Type' : 'application/json',
        }
        if(moreHeaders){
            for(let key in moreHeaders){
                headers[key] = moreHeaders[key];
            }
        }
        let options = {
            'headers' : new HttpHeaders(headers),
        }
        if(moreOptions){
            for(let key in moreOptions){
                options[key] = moreOptions[key];
            }
        }
        return this.http.post(url, data, options)
        .toPromise()
        .then(res => {
            return this.handleResponse(res);
        });
    }
    delete(url: string, moreHeaders?, moreOptions?){
        let headers = {
            'Content-Type' : 'application/json',
        }
        if(moreHeaders){
            for(let key in moreHeaders){
                headers[key] = moreHeaders[key];
            }
        }
        let options = {
            'headers' : new HttpHeaders(headers),
        }
        if(moreOptions){
            for(let key in moreOptions){
                options[key] = moreOptions[key];
            }
        }
        
        return this.http.delete(url, options)
        .toPromise()
        .then(res => {
            return this.handleResponse(res);
        });
    }
    put(url, data, moreHeaders?, moreOptions?): Promise<any> {
        let headers = {
            'Content-Type' : 'application/json',
        }
        if(moreHeaders){
            for(let key in moreHeaders){
                headers[key] = moreHeaders[key];
            }
        }
        let options = {
            'headers' : new HttpHeaders(headers),
        }
        if(moreOptions){
            for(let key in moreOptions){
                options[key] = moreOptions[key];
            }
        }
        return this.http.put(url, data, options)
        .toPromise()
        .then(res => {
            return this.handleResponse(res);
        });
    }
    createHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        // headers = headers.set('token', 'ad');
        return headers;
    }
    createOption(option_r?){
        let result = {};
        if(option_r){
            result = {...option_r};
        }
        let headers = this.createHeaders();
        result['headers'] = headers;
        return result;
    }
    postFormData(url, data, moreHeaders?, moreOptions?): Promise<any> {

        let headers = {
        }
        if(moreHeaders){
            for(let key in moreHeaders){
                headers[key] = moreHeaders[key];
            }
        }
        let options = {
            'headers' : new HttpHeaders(headers),
        }
        if(moreOptions){
            for(let key in moreOptions){
                options[key] = moreOptions[key];
            }
        }
        return this.http.post(url, data, options)
        .toPromise()
        .then(res => {
            return this.handleResponse(res);
        });
    }
}