<div class="spinner-overlay" *ngIf="isLoading">
    <div class="spinner-center">
        <mat-spinner></mat-spinner>
    </div>
</div>
<input type="file" 
    class="media-capture"
    accept="image/*"
    (change)="bgCropper.imageChangedEvent = $event; bgCropper.open()"
    (click)="backgroundUpload.value = null"
    #backgroundUpload
>
<app-cropper-modal #bgCropper
    [headerText]="'Upload Background'"
    [confirmText]="'Done'"
    [maintainAspectRatio]="true"
    [aspectRatio]="16 / 9"
    [format]="'jpeg'"
    (croppedEvent)="onBackgroundCropped($event)"
>
</app-cropper-modal>
<sb-top-nav *ngIf="siteDetails && contentBlogSettings"
    [faceShotImage]="visitorBlogSettings ? visitorBlogSettings.faceShotImage : null"
    [siteDetails]="siteDetails"
    [contentBlogUrl] = "contentBlogSettings ? contentBlogSettings.blogUrl : null"
    [contentTitle]="contentBlogSettings.blogTitle"
    [userBlogUrl]="visitorBlogSettings ? visitorBlogSettings.blogUrl : null"

    [onMyHomeComponent]="true"
>
</sb-top-nav>
<sb-clean-blog-header *ngIf="contentBlogSettings"
    imageContext="blogHome"
    [backgroundImage]="getBackgroundImage()"
    [heading]="contentBlogSettings.blogTitle"
    [subHeading]="contentBlogSettings.blogSubTitle"
    [siteHeading]="true"
    
    [edit]="editable()"
    (editEvent)="backgroundUpload.click()"
    [save]="newBackgroundImage ? true : false"
    (saveEvent)="saveChanges()"
    [cancel]="newBackgroundImage ? true : false"
    (cancelEvent)="cancelChanges()"

>
</sb-clean-blog-header>

<div class="list-posts" *ngIf="posts">
    <ng-container *ngIf="posts.length; else noPostsMessage">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                    <h1 i18n="posts heading|list of posts">Blog Posts</h1>
                    <hr>
                    <div class="post-preview" *ngFor="let post of posts; let i = index">
                        <a [routerLink]="['/', contentBlogSettings.blogUrl, 'post', post.entryId]"
                            [state]="{'post': post}">
                            <h2 class="post-title">{{post.heading}}</h2>
                            <h3 class="post-subtitle">{{post.subHeading}}</h3>
                        </a>
                        <p class="post-meta serif">{{post.dateCreated | date}}</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    
    <ng-template #noPostsMessage>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto empty-message serif" style="min-height: 200px;" i18n="empty list of posts">There are currently no blog posts.</div>
            </div>
        </div>
    </ng-template>
</div>

<sb-footer *ngIf="contentBlogSettings"
    [facebook]="contentBlogSettings.socialMediaLink1 ? contentBlogSettings.socialMediaLink1 : null"
    [twitter]="contentBlogSettings.socialMediaLink2 ? contentBlogSettings.socialMediaLink2 : null"
></sb-footer>