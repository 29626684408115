<header class="masthead" [style.background-image]="getSafeBackgroundImage()">
    <div class="overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-10 mx-auto">
                <ng-container *ngIf="siteHeading; else postHeading">
                    <div class="site-heading" [ngStyle]="{'padding': siteSettings ? '160px 0px' : null }">
                        <h1 *ngIf="!siteSettings" i18n>{heading, select, Contact Me {Contact Me} other {{{heading}}}}</h1>
                        <span *ngIf="!siteSettings" class="subheading">{{subHeading}}</span>                        
                        <div *ngIf="siteSettings" class="p-grid p-formgrid p-fluid site-settings">
                            <div class="p-field p-col-12 p-mb-4 p-lg-4 p-md-4 p-sm-4 p-mb-lg-0">
                                <span *ngIf="siteSettings" class="p-float-label">
                                    <input id="blogHeader" type="text" name="heading" autocomplete="off" pInputText [(ngModel)]="heading" class="p-inputtext-md">
                                    <label for="blogHeader">Heading</label>
                                </span>
                            </div>

                            <div class="p-col-12 p-mb-2 p-lg-4 p-md-4 p-sm-4 p-mb-lg-0">
                                <span *ngIf="siteSettings" class="p-float-label">
                                    <input id="blogSubHeader" type="text" name="subHeading" autocomplete="off" pInputText [(ngModel)]="subHeading" class="p-inputtext-md">
                                    <label for="blogSubHeader">Sub-Heading</label>
                                </span>
                            </div>

                            <div class="p-col-12 p-mb-2 p-lg-4 p-md-4 p-sm-4 p-mb-lg-0">
                                <span *ngIf="siteSettings" class="p-float-label">
                                    <input id="routeUrl" type="text" name="routeUrl" autocomplete="off" pInputText [(ngModel)]="routeManagementSiteUrl" class="p-inputtext-md">
                                    <label for="routeUrl">Route Management Site URL</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="siteSettings" class="text-center">
                        <button class="btn btn-success mr-2" (click)="updateSettings()">Save</button>
                        <button class="btn btn-danger" (click)="gotoHomePage()">Cancel</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="edit" class="edit-icon">
        <ul class="icons-list">
            <li>
                <fa-stack size="sm">
                    <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                    <fa-icon class="fa-stack-1x icon-element" (click)="editClicked()" [icon]='["fas", "pencil-alt"]' stackItemSize="1x"></fa-icon>
                </fa-stack>
            </li>
            <li *ngIf="save">
                <fa-stack size="sm">
                    <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                    <fa-icon class="fa-stack-1x icon-element" (click)="saveClicked()" [icon]="faSave" stackItemSize="1x"></fa-icon>
                </fa-stack>
            </li>
            <li *ngIf="cancel">
                <fa-stack size="sm">
                    <fa-icon class="fa-stack-2x icon-background" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon>
                    <fa-icon class="fa-stack-1x icon-element" (click)="cancelClicked()" [icon]="faWindowClose" stackItemSize="1x"></fa-icon>
                </fa-stack>
            </li>
        </ul>
    </div>
</header>
<ng-template #postHeading>
    <div class="post-heading">
        <h1>{{heading}}</h1>
        <h2 class="subheading">{{subHeading}}</h2>
        <span class="meta">{{meta}}</span>
    </div>
</ng-template>