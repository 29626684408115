import {
    Component,
    OnInit,
} from '@angular/core';
import { Post, BlogSettings } from '../../models';
import { Router } from '@angular/router';
import { LoginService } from '../../../providers/login.service';
import { HelperService } from '../../../providers/helper-service';
import { Subscription } from 'rxjs';
import { BlogService } from 'src/providers/blog.service';
import { base64ToFile } from 'ngx-image-cropper';
import { environment, MatchRiderSocialLinks } from 'src/environments/environment';

@Component({
    selector: 'sb-home',
    templateUrl: './home.component.html',
    styleUrls: ['home.component.scss'],
})
export class HomeComponent implements OnInit{

    isLoggedIn = false;
    isLoading: boolean = false;
    blogs: BlogSettings[];
    subscriptions: Subscription[] = [];

    siteDetails;

    blogSettings : BlogSettings;
    siteTitle: string = '';
    siteSubtitle: string = '';
    newBackgroundImage;
    bgImageToShow;
    mrFacebookLink = MatchRiderSocialLinks.facebook;
    mrTwitterLink = MatchRiderSocialLinks.twitter;
    constructor(
        private loginService: LoginService,
        private router: Router,
        private helperService: HelperService,
        private blogService: BlogService
        ){}
    ngOnInit(){
        
        this.isLoading = true;
        this.isLoggedIn = this.loginService.checkLoggedIn();
        const promises = [];
        const blogsPromise = this.blogService.getCachedHighlightedBlogs();
        const siteDetailsPromise = this.helperService.getSiteDetails();
        promises.push(blogsPromise, siteDetailsPromise);

        if(this.isLoggedIn){
            const bsPromise = this.blogService.getVisitorBlogSettings();
            promises.push(bsPromise);
        }
        Promise.all(promises)
        .then(resolutions => {
            this.blogs = resolutions[0];
            
            this.siteDetails = resolutions[1];
            this.bgImageToShow = this.siteDetails.BlogSiteBackgroundUrl;
            this.siteTitle = this.siteDetails.BlogSiteTitle;
            this.siteSubtitle = this.siteDetails.BlogSiteSubTitle;
            if(resolutions[2]){
                this.blogSettings = resolutions[2];
            }
            this.isLoading = false;
            
            this.blogService.getHighlightedBlogs()
            .then(blogs => {
                this.blogs = blogs;
            })
        })
        .catch(err => {
            this.isLoading = false;
            this.helperService.errorHandler(err);
        });
        
    }
    getBackgroundImage(){
        return this.bgImageToShow ? `url("${this.bgImageToShow}")` : null;
    }
    onBackgroundCropped(croppedImage){
        this.newBackgroundImage = croppedImage;
        this.bgImageToShow = croppedImage;
    }
    cancelChanges(){
        this.newBackgroundImage = null;
        this.bgImageToShow = this.siteDetails.BlogSiteBackgroundUrl;
    }
    saveChanges(){
        this.isLoading = true;
        let file = base64ToFile(this.newBackgroundImage);
        this.blogService.uploadBackgroundImage(file)
        .then( _ => {
            let newBgUrl = this.helperService.hackyUpdateBgUrl();
            
            this.siteDetails.BlogSiteBackgroundUrl = newBgUrl;
            this.bgImageToShow = this.siteDetails.BlogSiteBackgroundUrl;
            this.newBackgroundImage = null;

            this.isLoading = false;
            this.helperService.showSnackBar(environment.snackBarMessages.settingsChanged);
        })
        .catch(err => {
            this.isLoading = false;
            this.helperService.errorHandler(err);
        })
    }
    editable(){
        if(this.isLoggedIn && this.blogSettings && this.blogSettings.isSuperUser){
            return true;
        }
        return false;
    }
}
