import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BlogService } from './blog.service';
import { HelperService } from './helper-service';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate{
    constructor(
        private cookieService: CookieService,
        private blogservice: BlogService,
        private helperService: HelperService,
        private router: Router
    ){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean>{
        
        return this.blogservice.getUserBlogUrl()
        .then(blogUrl => {
            if(blogUrl && blogUrl === route.params['blogUrl']){
                return true;
            }
            else{
                return Promise.reject(new Error(environment.snackBarMessages.accessDenied));
            }
        })
        .catch(err => {
            this.helperService.errorHandler(err);
            this.router.navigateByUrl('/login');
            return Promise.resolve(false);
        })
    }
}

@Injectable({
    providedIn: "root"
})
export class AuthGuardForSuperAdmin implements CanActivate{
    constructor(
        private cookieService: CookieService,
        private loginService: LoginService,
        private blogService: BlogService,
        private helperService: HelperService,
        private router: Router
    ){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean>{
        let isLoggedIn = false;
        isLoggedIn = this.loginService.checkLoggedIn();
        if (isLoggedIn) {
            return this.blogService.getVisitorBlogSettings()
            .then(blogSettings => {
                if(isLoggedIn && blogSettings && blogSettings.isSuperUser){
                    return true;
                }
                else{
                    return Promise.reject(new Error(environment.snackBarMessages.accessDenied));
                }
            })
            .catch(err => {
                this.helperService.errorHandler(err);
                this.router.navigateByUrl('/login');
                return Promise.resolve(false);
            })
        } else{
            this.helperService.showSnackBar(environment.snackBarMessages.accessDenied);
            this.router.navigateByUrl('/login');
        }
    }
}