// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  RECAPTCHA_SITE_KEY: "6Le7kO8ZAAAAAB8kG3zVsEU1edIkD--lhZR9K_KN",

  snackBarMessages : {
    'basicErrorMessage' : $localize`:@@bar.basicErrorMessage:Something Went Wrong, Please Try Again`,
    'loginSuccess' : $localize`:@@bar.loginSuccess:Login Successful`,
    'logoutSuccess' : $localize`:@@bar.logoutSuccess:Logged Out !`,
    'messageSent' : $localize`:@@bar.messageSent:Message Sent`,
    'settingsChanged' : $localize`:@@bar.settingsChanged:Settings Changed Successfully`,
    'imageLoadingFailed' : $localize`:@@bar.imageLoadingFailed:Image Loading Failed`,
    'postUpdated' : $localize`:@@bar.postUpdated:Post Updated Successfully`,
    'postCreated' : $localize`:@@bar.postCreated:Post Created Successfully`,
    'postDeleted' : $localize`:@@bar.postDeleted:Post Deleted Successfully`,
    'enterValidForm' : $localize`:@@bar.enterValidForm:Please Enter a Valid Form`,
    'blogDeleted' : $localize`:@@bar.blogDeleted:The Blog is Deleted`,
    'accessDenied' : $localize`:@@bar.accessDenied:Access Denied.`,
    'notLoggedInMessage' : $localize`:@@bar.notLoggedInMessage:You are not Logged In`,
    'imageSizeLimitMessage' : $localize`:@@bar.imageSizeLimitMessage:Size Limit Exceeded: Image should be less than 5 MB`,
    'emptyField' : $localize`:@@bar.emptyField:Field is empty.`
  }
};

export const api_urls = {
  // API_ENDPOINT: 'https://fahrplantoolapi.azurewebsites.net/',
  // API_ENDPOINT: 'https://api.matchrider.de/',
  getHighlightedBlogEntries : "https://fahrplantoolapi.azurewebsites.net/api/blog/GetHighlightedBlogEntries",
  getHighlightedBlogs : "https://fahrplantoolapi.azurewebsites.net/api/blog/GetHighlightedBlogs",
  saveBlogEntry : "https://fahrplantoolapi.azurewebsites.net/api/blog/SaveBlogEntry",
  getBlogSettings : "https://fahrplantoolapi.azurewebsites.net/api/2.0/blog/GetBlogSettings",
  getBlogSettingsByUrl : blogUrl => `https://fahrplantoolapi.azurewebsites.net/api/blog/GetBlogSettingsByUrl/${blogUrl}`,
  saveBlogSettings : "https://fahrplantoolapi.azurewebsites.net/api/blog/SaveBlogSettings",
  saveBlogSiteSettings : "https://fahrplantoolapi.azurewebsites.net/api/Blog/SaveBlogSiteConnector",
  saveBlogFaceShot : "https://fahrplantoolapi.azurewebsites.net/api/blog/SaveBlogFaceShot",
  uploadImage : "https://fahrplantoolapi.azurewebsites.net/api/blog/UploadImage",
  uploadBackgroundImage : "https://fahrplantoolapi.azurewebsites.net/api/blog/UploadBackgroundImage",
  getBlogEntries : blogUrl => `https://fahrplantoolapi.azurewebsites.net/api/blog/GetBlogEntries/${blogUrl}`,
  getBlogEntry : (blogUrl, blogEntryId) => `https://fahrplantoolapi.azurewebsites.net/api/blog/GetBlogEntry/${blogUrl}/${blogEntryId}`,
  sendBlogEmail : "https://fahrplantoolapi.azurewebsites.net/api/blog/SendBlogEmail",
  deleteBlog : "https://fahrplantoolapi.azurewebsites.net/api/2.0/blog/DeleteBlog",
  deleteBlogEntry : blogEntryId => `https://fahrplantoolapi.azurewebsites.net/api/2.0/blog/DeleteBlogEntry/${blogEntryId}`,
  getBlogSiteConnector : "https://fahrplantoolapi.azurewebsites.net/api/blog/GetBlogSiteConnector",
  validateAdminBlogUser : "https://fahrplantoolapi.azurewebsites.net/api/login/ValidateAdminBlogUser",
  getAvailableRoutesForMap : communityId => `https://fahrplantoolapi.azurewebsites.net/api/preConfiguredRoute/GetAvailableRoutesByCommunityForMap/${communityId}`,
  getMatchPoints : communityId => `https://fahrplantoolapi.azurewebsites.net/api/preConfiguredRoute/MatchPointsOnPreconfiguredRoutesByCommunity/${communityId}`,
  getCommunityById: communityId => `https://fahrplantoolapi.azurewebsites.net/api/Community/GetCommunityById/${communityId}`
}

export const default_images = {
  faceshot : "assets/img/default-faceshot.png", // image ratio 1:1
  icon : "assets/img/matchrider-logo.png", // image ratio 1:1
  website : "assets/img/about-bg.jpg", // 16:9
  blogHome : "assets/img/home-bg.jpg", // 16:9
  aboutme : "assets/img/about-bg.jpg", // 16:9
  post : "assets/img/home-bg.jpg", // 16:9
  contact : "assets/img/contact-bg.jpg" // 16:9
}

export const MatchRiderSocialLinks = {
  facebook : 'https://www.facebook.com/Match.Rider',
  twitter : 'https://twitter.com/MatchRider'
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
