import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpHandlerService } from 'src/providers/http-handler.service';
import { AuthGuard } from 'src/providers/auth-guard.service';
import { IconsModule } from 'src/modules/icons/icons.module';
import { MarkdownModule } from 'ngx-markdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';

import { CookieService } from 'ngx-cookie-service';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactComponent } from './components/contact/contact.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { CleanBlogHeaderModule } from './modules/clean-blog-header/clean-blog-header.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { ImageUploadHelperModule } from './modules/image-upload-helper/image-upload-helper.module';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ShowAboutComponent } from './components/show-about/show-about.component';
import { MyHomeComponent } from './components/my-home/my-home.component';
import { PostComponent } from './components/post/post.component';
import { RouteTabComponent } from './components/route-tab/route-tab.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ButtonModule} from 'primeng/button';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import { BlogSiteSettingsComponent } from './components/blog-site-settings/blog-site-settings.component';

@NgModule({
    declarations: [
        AppComponent,
        ContactComponent,
        HomeComponent,
        LoginComponent,
        ShowAboutComponent,
        MyHomeComponent,
        PostComponent,
        RouteTabComponent,
        BlogSiteSettingsComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,

        AppRoutingModule,
        MarkdownModule.forRoot(),
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        CleanBlogHeaderModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ImageUploadHelperModule,
        FormsModule,
        InputSwitchModule,
        ButtonModule,
        MultiSelectModule,
        InputTextModule
    ],
    providers: [CookieService],
    bootstrap: [AppComponent],
})
export class AppModule {}
