import { Component, OnInit, OnDestroy } from "@angular/core";
import { BlogSettings } from 'src/app/models';
import { BlogService } from 'src/providers/blog.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/providers/helper-service';
import { LoginService } from 'src/providers/login.service';
import * as _ from "lodash";
import { faWindowClose, faSave } from '@fortawesome/free-solid-svg-icons';
import { base64ToFile } from 'ngx-image-cropper';
import { CacheService } from 'src/providers/cache.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-show-about',
    templateUrl: './show-about.component.html',
    styleUrls: ['./show-about.component.scss']
})
export class ShowAboutComponent implements OnInit{

    faWindowClose = faWindowClose;
    faSave = faSave;
    isLoggedIn: boolean = false;
    isLoading: boolean = true;
    visitorBlogSettings: BlogSettings;
    contentBlogSettings: BlogSettings;
    siteDetails;
    edit: boolean;
    newBackgroundImage;
    newFaceshotImage;
    bgImageToShow;
    fsImageToShow;

    constructor(
        private blogService: BlogService,
        private activatedRoute: ActivatedRoute,
        private helperService: HelperService,
        private loginService: LoginService,
        private cache: CacheService
    ){}
    ngOnInit(){
        this.isLoading = true;
        this.isLoggedIn = this.loginService.checkLoggedIn();
        const contentBlogUrl = this.activatedRoute.snapshot.params['blogUrl'];
        
        let bloggerBlogSettingsPromise;
        if(this.cache.bloggerBS && this.cache.bloggerBS.blogUrl === contentBlogUrl){
            bloggerBlogSettingsPromise = this.cache.getBloggerBlogSettings();
        }
        if(!this.isLoggedIn){
            const siteDetailsPromise = this.helperService.getSiteDetails();
            const contentBsPromise = bloggerBlogSettingsPromise ? bloggerBlogSettingsPromise : this.blogService.getBlogSettingsByUrl(contentBlogUrl);
            Promise.all([siteDetailsPromise, contentBsPromise])
            .then(resolutions => {
                this.siteDetails = resolutions[0];
                this.contentBlogSettings = resolutions[1];
                this.bgImageToShow = this.contentBlogSettings.aboutMeBackgroundUrl;
                this.fsImageToShow = this.contentBlogSettings.faceShotUrl;
                this.isLoading = false;

                if(bloggerBlogSettingsPromise){
                    this.blogService.getBlogSettingsByUrl(contentBlogUrl)
                    .then(bs => {
                        this.contentBlogSettings = bs;
                    })
                }
            })
            .catch(err => {
                this.isLoading = false;
                this.helperService.errorHandler(err);
            })
        }
        else{
            const siteDetailsPromise = this.helperService.getSiteDetails();
            const visitorBsPromise = this.blogService.getVisitorBlogSettings();

            Promise.all([siteDetailsPromise, visitorBsPromise])
            .then(resolutions => {
                this.siteDetails = resolutions[0];
                this.visitorBlogSettings = resolutions[1];
                if(this.visitorBlogSettings.blogUrl === contentBlogUrl){
                    this.edit = true;
                    return this.visitorBlogSettings;
                }
                else{
                    return bloggerBlogSettingsPromise ? bloggerBlogSettingsPromise : this.blogService.getBlogSettingsByUrl(contentBlogUrl);
                }
            })
            .then(contentBlogSettings => {
                this.contentBlogSettings = contentBlogSettings;
                this.bgImageToShow = this.contentBlogSettings.aboutMeBackgroundUrl;
                this.fsImageToShow = this.contentBlogSettings.faceShotUrl;
                this.isLoading = false;

                if(bloggerBlogSettingsPromise){
                    this.blogService.getBlogSettingsByUrl(contentBlogUrl)
                    .then(bs => {
                        this.contentBlogSettings = bs;
                    })
                }
            })
            .catch(err => {
                this.isLoading = false;
                this.helperService.errorHandler(err);
            })
        }
    }

    get backgroundImageUrl(){
        return this.bgImageToShow ? `url('${this.bgImageToShow}')` : null;
    }
    getDefaultFaceShot(){
        return this.helperService.getImagesOnError('faceshot');
    }
    onBackgroundCropped(croppedImage){
        this.newBackgroundImage = croppedImage;
        this.bgImageToShow = croppedImage;
    }
    onFaceshotCropped(croppedImage){
        this.newFaceshotImage = croppedImage;
        this.fsImageToShow = croppedImage;
    }
    saveBg(){
        this.isLoading = true;
        let file = base64ToFile(this.newBackgroundImage);
        this.blogService.uploadImage(file)
        .then(url => {
            const bs = _.cloneDeep(this.visitorBlogSettings);
            bs.aboutMeBackgroundUrl = url;
            return this.blogService.saveBlogSettings(bs);
        })
        .then( _ => {
            return this.blogService.getBlogSettingsByToken();
        })
        .then(bs => {
            this.contentBlogSettings = bs;
            this.visitorBlogSettings = bs;
            this.bgImageToShow = this.contentBlogSettings.aboutMeBackgroundUrl;
            this.newBackgroundImage = null;

            this.isLoading = false;
            this.helperService.showSnackBar(environment.snackBarMessages.settingsChanged);
        })
        .catch(err => {
            this.isLoading = false;
            this.helperService.errorHandler(err);
        })
    }
    cancelBg(){
        this.newBackgroundImage = null;
        this.bgImageToShow = this.contentBlogSettings.aboutMeBackgroundUrl;
    }
    saveFs(){
        this.isLoading = true;
        let file = base64ToFile(this.newFaceshotImage);
        this.blogService.uploadImage(file)
        .then(url => {
            const bs = _.cloneDeep(this.visitorBlogSettings);
            bs.faceShotUrl = url;
            return this.blogService.saveBlogSettings(bs);
        })
        .then( _ => {
            return this.blogService.getBlogSettingsByToken();
        })
        .then(bs => {
            this.contentBlogSettings = bs;
            this.visitorBlogSettings = bs;
            this.fsImageToShow = this.contentBlogSettings.faceShotUrl;
            this.newFaceshotImage = null;

            this.isLoading = false;
            this.helperService.showSnackBar(environment.snackBarMessages.settingsChanged);
        })
        .catch(err => {
            this.isLoading = false;
            this.helperService.errorHandler(err);
        })
    }
    cancelFs(){
        this.newFaceshotImage = null;
        this.fsImageToShow = this.contentBlogSettings.faceShotUrl;
    }
    toShowContactMe(): boolean{
        if(! this.contentBlogSettings){
            return false;
        }
        let contactable = this.contentBlogSettings.contactable;
        if(!this.visitorBlogSettings){
            return contactable;
        }
        else{
            let result = this.visitorBlogSettings.blogUrl === this.contentBlogSettings.blogUrl ? false : true;
            return result && contactable;
        }
    }
}