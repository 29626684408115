import { Injectable } from "@angular/core";
import { HttpHandlerService } from './http-handler.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { HelperService } from './helper-service';
import { CacheService } from './cache.service';
import { api_urls } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LoginService{

    isLoggedIn: boolean = false;
    constructor(
        private httpHandler: HttpHandlerService,
        private cookieService: CookieService,
        private router: Router,
        private helperService: HelperService,
        private cache: CacheService
    ){}
    login(email, password){
        const credentials = {email, password};
        let url = api_urls.validateAdminBlogUser;
        return this.httpHandler.post(url, credentials)
        .then(res => {
            let token = res.Payload[0].token;
            this.cookieService.set('token', token, {path: '/',sameSite: 'Lax', secure: true});
        })
    }
    checkLoggedIn(){
        return this.cookieService.check('token');
    }
    logout(){
        this.isLoggedIn = false;
        this.cookieService.deleteAll('/');
        localStorage.clear();
        this.helperService.clearValues();
        this.cache.clearCache();
        return this.router.navigateByUrl('/')
        .then(navigated => {
            window.location.reload();
        })
    }
    setUser(): Promise<any>{
        let url = "https://api.matchrider.de/api/2.0/Profile/GetAccountDetails";
        let token = this.cookieService.get('token');
        return this.httpHandler.get(url, {token})
        .then(res => {
            const user = res['Payload'][0];
            localStorage.setItem('user', JSON.stringify(user));
        })
    };
    setUserGivenUser(user):Promise<any>{
        localStorage.setItem('user', JSON.stringify(user));
        return Promise.resolve();
    }
}