import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/providers/login.service';
import { BlogService } from 'src/providers/blog.service';
import { BlogSettings } from 'src/app/models';
import { CookieService } from 'ngx-cookie-service';
import { HelperService } from 'src/providers/helper-service';
import { environment } from 'src/environments/environment';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
    selector: 'sb-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit{
    faSave = faSave;
    faWindowClose = faWindowClose;
    isLoggedIn = false;
    isLoading = false;
    isOnPost = false;
    isMenuCollapsed = true;
    isShowRoute = false;
    imagePath: string;
    blogTitle;
    isSaveClicked = false;
    contentBlogSettings: BlogSettings;
    visitorBlogSettings: BlogSettings;
    @Input() onMyHomeComponent: boolean;
    @Input() disableFaceShot: boolean = false;

    @Input() faceShotImage: string;
    @Input() blogIconImage: string;
    @Input() siteDetails;
    @Input() siteSettings = false;
    @Input() edit = false;
    @Input() save: boolean;
    @Input() cancel: boolean;
    @Input() imageContext;

    @Input() contentBlogUrl: string;
    @Input() contentTitle: string;
    @Input() userBlogUrl: string;
    @Input() editButtonDetails: {
        toShow: boolean,
        blogEntryId: number
    } = {
        toShow: false,
        blogEntryId: 0
    }
    @Output() editEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveEvent: EventEmitter<any> = new EventEmitter();
    @Output() cancelEvent: EventEmitter<any> = new EventEmitter();


    constructor(
        private domSanitizer: DomSanitizer,
        private loginService: LoginService,
        private blogService: BlogService,
        private router: Router,
        private cookieService: CookieService,
        private helperService : HelperService,
        private activatedRoute: ActivatedRoute
    ){}
    ngOnInit(){
        this.isLoggedIn = this.loginService.checkLoggedIn();
        this.isLoading = true;
        const contentBlogUrl = this.activatedRoute.snapshot.params['blogUrl'];
        if (!this.isLoggedIn) {
          const siteDetailsPromise = this.helperService.getSiteDetails();
          const contentBsPromise = this.blogService.getBlogSettingsByUrl(contentBlogUrl);
          Promise.all([siteDetailsPromise, contentBsPromise])
            .then(resolutions => {
              this.siteDetails = resolutions[0];
              this.contentBlogSettings = resolutions[1];
              this.blogTitle = this.siteDetails.BlogSiteTitleShort;
    
              // show tab
              this.showRouteTab();
              this.isLoading = false;
            })
            .catch(err => {
              this.isLoading = false;
              this.helperService.errorHandler(err);
            })
        }
        else {
          const siteDetailsPromise = this.helperService.getSiteDetails();
          const visitorBsPromise = this.blogService.getVisitorBlogSettings();
    
          Promise.all([siteDetailsPromise, visitorBsPromise])
            .then(resolutions => {
              this.siteDetails = resolutions[0];
              this.visitorBlogSettings = resolutions[1];
              if (this.visitorBlogSettings.blogUrl === contentBlogUrl) {
                return this.visitorBlogSettings;
              }
              else {
                return this.blogService.getBlogSettingsByUrl(contentBlogUrl);
              }
            })
            .then(contentBlogSettings => {
              this.contentBlogSettings = contentBlogSettings;
              // show tab
              this.showRouteTab();
              this.isLoading = false;
            })
            .catch(err => {
              this.isLoading = false;
              this.helperService.errorHandler(err);
            })
        }
    }
    doLogout(){
        this.isLoading = true;
        this.blogService.clearValues();
        this.loginService.logout()
        .then( _ => {
            this.helperService.showSnackBar(environment.snackBarMessages.logoutSuccess);
            this.isLoading = false;
        })
        .catch(err => {
            this.isLoading = false;
            this.helperService.errorHandler(err);
        })
    }
    getDefaultFaceShot(){
        return this.helperService.getImagesOnError('faceshot');
    }
    onMyBlog(){
        return this.contentBlogUrl && (this.contentBlogUrl === this.userBlogUrl);
    }
    routeToMyHome(){
        this.router.navigate(['/'], {skipLocationChange: true})
        .then( _ => this.router.navigate(['/', this.userBlogUrl]))
    }
    toHighlightMyHome(){
        if(this.contentBlogUrl 
            && this.userBlogUrl
            && (this.contentBlogUrl === this.userBlogUrl)
            && this.onMyHomeComponent
        ){
            return true;
        }
        return false;
    }

  showRouteTab(): boolean {
    if ((this.contentBlogSettings && this.contentBlogSettings.routeTab)) {
      this.isShowRoute = this.contentBlogSettings.routeTab;
    }
    return this.isShowRoute;
  }

  editable() {
    if (this.isLoggedIn && this.visitorBlogSettings && this.visitorBlogSettings.isSuperUser) {
      return true;
    }
    return false;
  }

  editClicked() {
    this.editEvent.emit(null);
  }

  saveClicked() {
    this.isSaveClicked = true;
    this.saveEvent.emit(null);
  }
  cancelClicked() {
    this.cancelEvent.emit(null);
  }

  getSafeIconImage() {
    if (!this.blogIconImage) {
      let bg = this.helperService.getImagesOnError(this.imageContext);
      bg = `url('${bg}')`;
      return this.domSanitizer.bypassSecurityTrustStyle(bg);
    }
    else {
      return this.domSanitizer.bypassSecurityTrustStyle(this.blogIconImage);
    }
  }
}
