import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { CleanBlogHeaderComponent } from './clean-blog-header.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputTextModule } from "primeng/inputtext";
import { FormsModule } from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        InputTextModule
    ],
    declarations: [
        CleanBlogHeaderComponent
    ],
    providers: [],
    exports: [
        CleanBlogHeaderComponent
    ]
})
export class CleanBlogHeaderModule{}