import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BlogSettings } from 'src/app/models';
import { Router, ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/providers/blog.service';
import { HelperService } from 'src/providers/helper-service';
import { LoginService } from 'src/providers/login.service';
import { } from "googlemaps";
import { first } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment, MatchRiderSocialLinks } from 'src/environments/environment';

@Component({
  selector: 'app-route-tab',
  templateUrl: './route-tab.component.html',
  styleUrls: ['./route-tab.component.scss']
})
export class RouteTabComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  mpPoly: google.maps.Polyline;
  mpInfowindow = new google.maps.InfoWindow({ content: '' });
  mpPolyLines: any[] = [];
  rideRoute: any[] = [];
  inComingRoute: any[] = [];
  outGoingRoute: any[] = [];
  circularRoute: any[] = [];
  availableRoutes: any[] = [];
  matchPoints: any[] = [];
  isavailableRoute = false;
  lineColor: string;
  preConfiguredRouteColor = {};
  loading = false;
  pmarker;
  mpMarkers = []; //Markers displayed on Map
  isMPsChecked: boolean = false;
  communityId = 0;
  selectedLine: any[] = [];
  groupedRoutes: any[] = [];
  selectedLineMPs: any[] = [];
  routes: any[] = [];
  isLoading: boolean;
  isLoggedIn: boolean;
  siteDetails;
  contentBlogSettings: BlogSettings;
  visitorBlogSettings: BlogSettings;
  mrFacebookLink = MatchRiderSocialLinks.facebook;
  mrTwitterLink = MatchRiderSocialLinks.twitter;

  constructor(
    private blogService: BlogService,
    private helperService: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.isLoggedIn = this.loginService.checkLoggedIn();
    const contentBlogUrl = this.activatedRoute.snapshot.params['blogUrl'];
    const siteDetailsPromise = this.helperService.getSiteDetails();
    const contentBsPromise = this.blogService.getBlogSettingsByUrl(contentBlogUrl);
    Promise.all([siteDetailsPromise, contentBsPromise])
      .then(resolutions => {
        this.siteDetails = resolutions[0];
        this.contentBlogSettings = resolutions[1];
        let latlng = new google.maps.LatLng(51.1657, 10.4515);
        let mapProp = {
          zoom: 6,
          center: latlng,
          clickableIcons: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          draggableCursor: 'arrow',
          draggingCursor: 'arrow'
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        this.communityId = this.contentBlogSettings.communityId > 0 ? this.contentBlogSettings.communityId : 0;
        this.loadComunity(this.communityId);  
        this.loadMatchPointsByCommunity();
        // this.loadAvailableRoutesForMap();
        // show tab
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        this.helperService.errorHandler(err);
      })
    
    if (this.isLoggedIn) {
      const siteDetailsPromise = this.helperService.getSiteDetails();
      const visitorBsPromise = this.blogService.getVisitorBlogSettings();

      Promise.all([siteDetailsPromise, visitorBsPromise])
        .then(resolutions => {
          this.siteDetails = resolutions[0];
          this.visitorBlogSettings = resolutions[1];

          if (this.visitorBlogSettings.blogUrl === contentBlogUrl) {
            return this.visitorBlogSettings;
          }
          else {
            return this.blogService.getBlogSettingsByUrl(contentBlogUrl);
          }
        })
        .then(contentBlogSettings => {
          this.contentBlogSettings = contentBlogSettings;
          // show tab
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          this.helperService.errorHandler(err);
        })
    }

  }

  getBackgroundImageUrl() {
    return this.contentBlogSettings.homePageBackgroundUrl ? `url(${this.contentBlogSettings.homePageBackgroundUrl})` : null;
  }

  routeToBlogUrl() {
    if (this.contentBlogSettings) return this.router.navigate(['/', this.contentBlogSettings.blogUrl]);
  }

  loadComunity(communityId) {
    this.blogService.getCommunityById(communityId)
      .then(
        data => {
          if (data.Payload.length == 1 && data.Payload[0].Latitude != null) {
            let pmarker;
            let htmlM = '<div style=line-height:2.90;overflow:hidden;white-space:nowrap;font-weight:500;letter-spacing:1.5px;>' + data.Payload[0].Name + '</div>';
            this.map.setOptions({ center: new google.maps.LatLng(data.Payload[0].Latitude, data.Payload[0].Longitude), zoom: 13 });
            pmarker = new google.maps.Marker({ position: new google.maps.LatLng(data.Payload[0].Latitude, data.Payload[0].Longitude), map: this.map, zIndex: 1, icon: './assets/img/markerR.png' });
            pmarker.addListener('click', () => {
              this.mpInfowindow.close(); this.mpInfowindow.setContent(htmlM); this.mpInfowindow.open(this.map, pmarker);
            });
            // this.map.setOptions({ center: new google.maps.LatLng(data.Payload[0].Latitude, data.Payload[0].Longitude), zoom: 13 });
            // new google.maps.Marker({ position: new google.maps.LatLng(data.Payload[0].Latitude, data.Payload[0].Longitude), map: this.map, zIndex: 1, icon: '../../../assets/img/markerR.png' });
          }
        },
        error => {
          this.helperService.errorHandler(error);
          this.loading = false;
        });
  }

  loadAvailableRoutesForMap() {
    this.loading = true;
    this.availableRoutes = [];
    this.inComingRoute = [];
    this.outGoingRoute = [];
    this.circularRoute = [];
    // this.routeBounds = new google.maps.LatLngBounds();
    this.blogService.getAvailableRoutesForMap(this.communityId)
      .then(data => {
        if (data.Status == "Success") {
          this.loading = false;
          data.Payload.forEach(ar => {
            this.availableRoutes.push(ar);
            this.isavailableRoute = true;

            if (ar.routeDirection == 1) {
              this.inComingRoute.push(ar);
            } else if (ar.routeDirection == 2) {
              this.outGoingRoute.push(ar);
            } else if (ar.routeDirection == 3) {
              this.circularRoute.push(ar);
            }
          })

          this.groupedRoutes = [
            {
                label: 'InComing', value: 'de', 
                items: this.inComingRoute
            },
            {
                label: 'OutGoing', value: 'de', 
                items: this.outGoingRoute
            },
            {
                label: 'Circular', value: 'de', 
                items: this.circularRoute
            }
        ];                                                  
        this.createRoute(this.inComingRoute);
        } else {
          this.helperService.errorHandler(data.Payload[0].error);
          this.loading = false;
        }
      },
        error => {
          this.helperService.errorHandler(error);
          this.loading = false;
        });
  }

  createRoute(route) {
    this.selectedLine = route;
    this.clearPolyLine();
    if (this.isMPsChecked) {
      this.isMPsChecked = false;
      this.mpClearmarkers();
    }

    route.forEach(r => {
      this.rideRoute = r.rideRoute;
      let polyPoints = [];
      let bounds = new google.maps.LatLngBounds();
      this.rideRoute.forEach(ro => {
        polyPoints.push(new google.maps.LatLng(ro.latitude, ro.longitude));
        bounds.extend(polyPoints[polyPoints.length - 1]);
      });

      this.lineColor = this.preConfiguredRouteColor[r.id];
      this.mpPoly = new google.maps.Polyline({
        path: polyPoints,
        strokeColor: this.lineColor,
        strokeOpacity: 1,
        strokeWeight: 4,
        map: this.map
      });
      this.mpPolyLines.push(this.mpPoly);
    });
  }

  clearPolyLine() {
    this.mpPolyLines.forEach(mp => {
      mp.setMap(null);
    });
  }

  loadMatchPointsByCommunity() {
    this.loading = true;
    this.blogService.getMatchPointsByCommunity(this.communityId)
      .then(data => {
        if (data.Status == "Success") {
          this.loading = false;
          this.matchPoints = data.Payload;
          this.matchPoints.forEach(mp => {
            _.set(this.preConfiguredRouteColor, mp.PreconfiguredRouteId, mp.HighwayColor);
          });
          this.loadAvailableRoutesForMap();
        } else {
          this.helperService.errorHandler(data.Payload[0].error);
          this.loading = false;
        }
      },
        error => {
          this.helperService.errorHandler(error);
          this.loading = false;
        });
  }

  //Clear all Markers on map
  mpClearmarkers() {
    for (var i = 0; i < this.mpMarkers.length; i++) {
      this.mpMarkers[i].setMap(null);
    }
    this.mpMarkers = [];
  }

  showAllStops() {
    if (this.isMPsChecked) {
      this.selectedLine.forEach(sl => {
        this.selectedLineMPs = (_.filter(this.matchPoints, { 'PreconfiguredRouteId': sl.id }));
        this.selectedLineMPs.forEach(mp => {
          let pmarker;
          let htmlM = '<div style=line-height:2.90;overflow:hidden;white-space:nowrap;font-weight:500;letter-spacing:1.5px;>' + mp.LocationName + '</div>';
          pmarker = new google.maps.Marker({ position: new google.maps.LatLng(mp.Latitude, mp.Longitude), map: this.map, zIndex: 2, icon: '../assets/img/markerMP.png' });
          this.mpMarkers.push(pmarker);
          pmarker.addListener('click', () => {
            this.mpInfowindow.close(); this.mpInfowindow.setContent(htmlM); this.mpInfowindow.open(this.map, pmarker);
          });
          // this.pmarker = new google.maps.Marker({ position: new google.maps.LatLng(mp.Latitude, mp.Longitude), map: this.map, zIndex: 2, icon: '../assets/img/markerMP.png' });
          // this.mpMarkers.push(this.pmarker);
        });
      });
    } else {
      this.mpClearmarkers();
    }
  }

  toggleMPsStop() {
    this.isMPsChecked = !this.isMPsChecked;
    this.showAllStops();
  }
  
  selectedLinesOnMap() {
      if (this.selectedLine.length) {
          this.createRoute(this.selectedLine);   
      } else {
          this.selectedLine = this.inComingRoute;
          this.createRoute(this.inComingRoute);
      }
  }
}
